import React from "react";
import "./Copyright.scss";

const Copyright = () => {

  const year = () => new Date().getFullYear(); 

  return (
    <div className="copyright">
      <div className="copyright_info">
        <small>Copyright © {year()} Notions Group. All Rights Reserved.</small>
      </div>
    </div>
  );
};

export default Copyright;
