import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import HomeIcon from "@mui/icons-material/Home";
import Cart from "../Cart/Cart";
import { useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import "./mobile.css";
import { useLanguage } from "../../hooks/LanguageContext";


const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);
   const [showDropdown, setShowDropdown] = useState(false);
  const products = useSelector((state) => state.cart.products);

const { language, setLanguage, translations } = useLanguage();

  const [show, setShow] = useState(false);


   const languageOptions = {
     en: { name: "English", flag: require("../../img/5111640.png") },
     ar: { name: "عربي", flag: require("../../img/206719.png") },
     ru: { name: "Русский", flag: require("../../img/206604.png") },
     ja: { name: "日本語", flag: require("../../img/japan_flag.jpg") },
     ko: { name: "한국어", flag: require("../../img/korea_flag.png") },
     hi: { name: "हिंदी", flag: require("../../img/india_flag.png") },
     zh: { name: "中文", flag: require("../../img/china_flag.png") },
   };

   const handleSelectLanguage = (lang) => {
     setLanguage(lang);
     setShowDropdown(false); // Close dropdown after selection
   };



  const content = {
    en: {
      shop: "Shop",
      newRelease: "New Release",
      gourmet: "Gourmet",
      vegan: "Vegan",
      bites: "Bites",
      promotions: "Promotions",
      about: "About",
      contact: "Contact",
      subscribe: "Subscribe",
      english: "English",
      arabic: "عربي",
      russian: "Русский",
      japanese: "日本語",
      korean: "한국어",
      hindi: "हिंदी", // Added Hindi for India
      chinese: "中文", // Added Chinese for China
    },
    ar: {
      shop: "تسوق",
      newRelease: "إصدار جديد",
      gourmet: "جورميه",
      vegan: "نباتي",
      bites: "لقيمات",
      promotions: "عروض",
      about: "حول",
      contact: "اتصل",
      subscribe: "اشترك",
      english: "English",
      arabic: "عربي",
      russian: "Русский",
      japanese: "日本語",
      korean: "한국어",
      hindi: "हिंदी",
      chinese: "中文",
    },
    ru: {
      shop: "Магазин",
      newRelease: "Новый выпуск",
      gourmet: "Гурме",
      vegan: "Веганский",
      bites: "Закуски",
      promotions: "Акции",
      about: "О нас",
      contact: "Контакт",
      subscribe: "Подписаться",
      english: "English",
      arabic: "عربي",
      russian: "Русский",
      japanese: "日本語",
      korean: "한국어",
      hindi: "हिंदी",
      chinese: "中文",
    },
    ja: {
      shop: "ショップ",
      newRelease: "新商品",
      gourmet: "グルメ",
      vegan: "ビーガン",
      bites: "軽食",
      promotions: "プロモーション",
      about: "について",
      contact: "お問い合わせ",
      subscribe: "登録",
      english: "英語",
      arabic: "アラビア語",
      russian: "ロシア語",
      japanese: "日本語",
      korean: "韓国語",
      hindi: "हिंदी",
      chinese: "中文",
    },
    ko: {
      shop: "상점",
      newRelease: "신상품",
      gourmet: "미식",
      vegan: "비건",
      bites: "간식",
      promotions: "프로모션",
      about: "소개",
      contact: "연락처",
      subscribe: "구독",
      english: "영어",
      arabic: "아랍어",
      russian: "러시아어",
      japanese: "일본어",
      korean: "한국어",
      hindi: "हिंदी",
      chinese: "中文",
    },
    hi: {
      shop: "शॉप",
      newRelease: "नई रिहाई",
      gourmet: "गौरमेट",
      vegan: "वीगन",
      bites: "नाश्ता",
      promotions: "प्रमोशन",
      about: "बारे में",
      contact: "संपर्क करें",
      subscribe: "सदस्यता लें",
      english: "English",
      arabic: "عربي",
      russian: "Русский",
      japanese: "日本語",
      korean: "한국어",
      hindi: "हिंदी",
      chinese: "中文",
    },
    zh: {
      shop: "商店",
      newRelease: "新发布",
      gourmet: "美食",
      vegan: "素食",
      bites: "小吃",
      promotions: "促销",
      about: "关于",
      contact: "联系我们",
      subscribe: "订阅",
      english: "English",
      arabic: "عربي",
      russian: "Русский",
      japanese: "日本語",
      korean: "한국어",
      hindi: "हिंदी",
      chinese: "中文",
    },
  };


  const toggleDropdown = () => {
    setShow(!show);
  };
  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  useEffect(() => {
    handleMouseLeave();
  }, [Link]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="my-navbar">
        <nav class="navbar navbar-expand-lg ">
          <div class="container-fluid">
            <div className="logo-top">
              <div>
                <button
                  class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="toggler-icon top-bar"></span>
                  <span class="toggler-icon middle-bar"></span>
                  <span class="toggler-icon bottom-bar"></span>
                </button>
              </div>

              <div className="center">
                <Link onClick={scrollToTop} className="link " to="/">
                  <img src={require("../../img/logo.webp")} alt="" />
                </Link>
              </div>

              <div className="item">
                <div className="icons">
                  <div className="cartIcon" onClick={() => setOpen(!open)}>
                    <ShoppingCartIcon />
                    <span>{products.length}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/products/1"
                  >
                    {content[language].shop}
                  </Link>
                </li>

                <div className="item dropDown" onClick={toggleDropdown}>
                  <span onClick={scrollToTop}>
                    {content[language].newRelease}
                  </span>
                  {show && (
                    <div className="dropDownMob">
                      <div className="nav-item">
                        <Link className="link hover" to="/gourmet">
                          {content[language].gourmet}
                        </Link>
                      </div>
                      <div className="nav-item">
                        <Link className="link hover" to="/vegan">
                          {content[language].vegan}
                        </Link>
                      </div>
                      <div className="nav-item">
                        <Link className="link hover" to="/bites">
                          {content[language].bites}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/promotion"
                  >
                    {content[language].promotions}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/about"
                  >
                    {content[language].about}
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    onClick={scrollToTop}
                    className="link hover"
                    to="/contact"
                  >
                    {content[language].contact}
                  </Link>
                </li>
                <li>
                  <div className="language">
                    <div className="dropdown">
                      <button
                        className="dropdown-btn"
                        onClick={() => setShowDropdown(!showDropdown)}
                      >
                        <img
                          src={languageOptions[language]?.flag}
                          alt={`${languageOptions[language]?.name} flag`}
                          className="flag-icon"
                        />
                        {languageOptions[language]?.name}
                      </button>
                      {showDropdown && (
                        <div className="dropdown-content">
                          {Object.entries(languageOptions).map(
                            ([lang, data]) => (
                              <button
                                key={lang}
                                onClick={() => handleSelectLanguage(lang)}
                                className="lang_btn"
                              >
                                <img
                                  src={data.flag}
                                  alt={`${data.name} flag`}
                                  className="flag-icon"
                                />
                                {data.name}
                              </button>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* ------------desktop wall paper----------------- */}

        <div className="wrapper" onMouseEnter={handleMouseLeave}>
          <div className="center">
            <Link onClick={scrollToTop} className="link hover" to="/">
              <img src={require("../../img/logo.webp")} alt="" />
            </Link>
          </div>
          <div></div>

          <div className="right">
            <div className="item">
              <Link
                onClick={scrollToTop}
                className="link hover"
                to="/products/1"
              >
                {content[language].shop}
              </Link>
            </div>
            {/* <div className="item">
            <Link onClick={scrollToTop} className="link" to="/new-brands">
              <em
                style={{
                  color: "#f1c40f",
                  fontSize: "12px",
                  fontStyle: "normal",
                }}
              >
                New
              </em>{" "}
              Release
            </Link>
          </div> */}

            <div
              className="item dropDownDeskTopWrp "
              onMouseEnter={handleMouseEnter}
            >
              <div className="nav_wrp">
                <span onClick={scrollToTop} className="hover">
                  {content[language].newRelease}
                </span>

                {show && (
                  <div className="dropDownDeskTop">
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/gourmet">
                        {content[language].gourmet}
                      </Link>
                    </div>
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/vegan">
                        {content[language].vegan}
                      </Link>
                    </div>
                    <div className="nav-item" onClick={handleMouseLeave}>
                      <Link className="link hover" to="/bites">
                        {content[language].bites}
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="item">
              <Link
                onClick={scrollToTop}
                className="link hover"
                to="/promotion"
              >
                {content[language].promotions}
              </Link>
            </div>

            <div className="item">
              <Link onClick={scrollToTop} className="link hover" to="/about">
                {content[language].about}
              </Link>
            </div>

            <div className="item">
              <Link onClick={scrollToTop} className="link hover" to="/contact">
                {content[language].contact}
              </Link>
            </div>

            <div className="item">
              <div className="icons">
                <div className="cartIcon" onClick={() => setOpen(!open)}>
                  <ShoppingCartIcon />
                  <span>{products.length}</span>
                </div>
              </div>
              <div></div>
            </div>

            <div className="language">
              <div className="dropdown">
                <button
                  className="dropdown-btn"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <img
                    src={languageOptions[language]?.flag}
                    alt={`${languageOptions[language]?.name} flag`}
                    className="flag-icon"
                  />
                  {languageOptions[language]?.name}
                </button>
                {showDropdown && (
                  <div className="dropdown-content">
                    {Object.entries(languageOptions).map(([lang, data]) => (
                      <button
                        key={lang}
                        onClick={() => handleSelectLanguage(lang)}
                        className="lang_btn"
                      >
                        <img
                          src={data.flag}
                          alt={`${data.name} flag`}
                          className="flag-icon"
                        />
                        {data.name}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {open && <Cart />}
      </div>
    </>
  );
};

export default Navbar;
