import "./About.scss";
import Newsletter from "../../components/Newsletter/Newsletter";
import { useLanguage } from "../../hooks/LanguageContext";

const About = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      companyProfile: {
        title: "Company Profile",
        description:
          "Notions Group is a dynamic group of companies innovating, manufacturing, marketing, and distributing brands that our consumers have grown to love. Headquartered in the ever-changing city of Dubai, we focus on building confectionery, date, and snack brands that reflect both heritage and modernity. The journey started in 1992 when our CEO, Fawaz Masri, created Dubai’s original guilt-free Chocodate, which has become the chosen healthy & delicious treat for enlightened consumers globally.",
      },
      pillars: {
        title: "Notions Group is built on two pillars",
        items: [
          {
            title: "La Ronda",
            description:
              "A Dubai-based chocolate factory specialized in producing and distributing FMCG & gourmet Chocodate, chocolate & date brands using the latest state-of-the-art technology all under internationally accredited hygienic standards.",
          },
          {
            title: "Star Foods",
            description:
              "A Saudi-based savory snacks factory manufacturing a variety of chips, crisps, and other snacks using leading technologies in the most efficient, industrial, and sustainable methods.",
          },
        ],
        description:
          "Our roots lie in the Middle East but our vision spans globally. The group was founded with the aim of crafting unique delicacies and creating unforgettable moments through our iconic brands for people of all ages and nationalities. Today we are expanding our world through continuous research, adding new categories and customers without losing sight of what makes us unique along with our vision that has always revolved around our consumers. We pride ourselves as the preferred private label partner of choice for many global powerhouses such as Mars, Carrefour, Lulu, Aldi, BurjKhalifa, Harrods and counting. ",
        subDescription:
          "As a family run business, we focus on nurturing long term partnerships with our suppliers, clients and employees. With a relentless focus on quality, innovation and detail we’re able to pursue our never-ending mission of creating value for our consumers.",
      },

      leadership: {
        title: "Leadership Team",
        members: [
          { name: "Fawaz Al-Masri", position: "Founder & CEO" },
          {
            name: "Razan Al-Masri",
            position: "Chief Business Development Officer, Notions Global",
          },
          {
            name: "Omar-Al-Masri",
            position: "Chief Operating Officer, Notions Global",
          },
          {
            name: "Hazem Al Masri",
            position: "Operations Manager, Snacks Division",
          },
        ],
      },
      corporate: {
        title: "Corporate Values",
        values: [
          {
            title:
              "Our brands combine superior Notions of the world; so do the principles we live by",
            description:
              "For a model that has worked so well with many of our products,we use the same equation to form our corporate values.",
          },
          {
            title: "Reason and Passion",
            description:
              "Passion is the force that drives us; reason is what steers our endeavours. We put our hearts and our minds in every little detail of the business to achieve the best results. We are passionate about our brands, our products, our customers, and our people, because they are the reason we are here, and that is what makes us successful.",
          },
          {
            title: "Tradition and Modernity",
            description:
              "Driven by the values of our ancestors; acquainted with the innovations of the future. Taking the old and making it new is a signature feature of many of Notions products; our company takes what has been good for years and makes it better with a modern twist. We also continue to follow traditional values as we stay on the cutting edge of technology and place innovation at the centre of everything we do.",
          },
          {
            title: "Family and Community",
            description:
              "Our enterprise is governed by family values, for the benefit of the community in which we serve. Notions is a family-run business that prides itself on making the business a home.Each and every member of the team plays an important part and we build their motivation by encouraging trust, stability,creativity and spontaneity. As part of a community, our combined efforts are motivated by offering only the best to our clients.",
          },
          {
            title: "Vision and Focus",
            description:
              "Our vision is broad, but our focus is narrow. It is our mutual goal to make our products the preferred choice in the world; we remain focused on developing our corporate identity in a way that will lead us there. We decide who and what we serve, because instead of being everything to everybody, we would rather be something special to somebody. This is what keeps us dedicated to what is really important, and directs our efforts as we get there.",
          },
          {
            title: "Excellence and Satisfaction",
            description:
              "The continued provision of excellence stems from satisfaction. In order to guarantee excellence, it starts with the satisfaction of our employees. This is why we work on keeping open communication between management and staff while encouraging progress through training and continuous education. We also follow the same with our customers, by listening to their suggestions to make improvements were possible and necessary.",
          },
        ],
      },
    },
    ar: {
      companyProfile: {
        title: "ملف الشركة",
        description:
          "مجموعة نوتيونز هي مجموعة ديناميكية من الشركات التي تبتكر وتنتج وتسوق وتوزع علامات تجارية أحبها مستهلكونا. يقع مقرنا الرئيسي في مدينة دبي المتغيرة باستمرار، ونركز على بناء علامات تجارية للحلويات والتمور والوجبات الخفيفة تعكس التراث والحداثة معًا. بدأت الرحلة في عام 1992 عندما أنشأ مديرنا التنفيذي، فواز المصري، شوكوديت الأصلية الخالية من الذنب في دبي، والتي أصبحت الوجبة الصحية واللذيذة المختارة للمستهلكين المستنيرين عالميًا.",
      },
      pillars: {
        title: "مجموعة نوتيونز مبنية على عمودين",
        items: [
          {
            title: "لا روندا",
            description:
              "مصنع شوكولاتة مقره دبي متخصص في إنتاج وتوزيع العلامات التجارية للحلويات والتمور باستخدام أحدث التقنيات مع الحفاظ على معايير صحية معتمدة دوليًا.",
          },
          {
            title: "ستار فودز",
            description:
              "مصنع سعودي للوجبات الخفيفة المالحة، يقوم بتصنيع مجموعة متنوعة من الرقائق والوجبات الخفيفة الأخرى باستخدام أحدث التقنيات بطريقة فعالة ومستدامة.",
          },
        ],
        description:
          "جذورنا تمتد في الشرق الأوسط، ولكن رؤيتنا تشمل العالم بأسره. تأسست المجموعة بهدف ابتكار مأكولات مميزة وخلق لحظات لا تُنسى من خلال علاماتها التجارية الأيقونية التي تناسب جميع الأعمار والجنسيات. واليوم، نقوم بتوسيع آفاقنا من خلال البحث المستمر، مضيفين فئات جديدة من المنتجات والعملاء، دون أن نغفل ما يجعلنا فريدين ورؤيتنا التي تدور دائمًا حول المستهلكين. نفخر بأننا الشريك المفضل للعديد من العلامات التجارية العالمية الرائدة مثل مارس، كارفور، لولو، ألدي، برج خليفة، هارودز، والمزيد. ",
        subDescription:
          " بصفتنا شركة عائلية، نركز على بناء شراكات طويلة الأمد مع موردينا وعملائنا وموظفينا. ومن خلال تركيزنا المستمر على الجودة والابتكار والتفاصيل، نستطيع مواصلة مهمتنا الدائمة في خلق قيمة لمستهلكينا.",
      },
      leadership: {
        title: "فريق القيادة",
        members: [
          { name: "فواز المصري", position: "المؤسس والمدير التنفيذي" },
          {
            name: "رزان المصري",
            position: "رئيسة تطوير الأعمال العالمية",
          },
          { name: "عمر المصري", position: "الرئيس التنفيذي للعمليات العالمية" },
          {
            name: "حازم المصري",
            position: "مدير العمليات، قسم الوجبات الخفيفة",
          },
        ],
      },
      corporate: {
        title: "القيم المؤسسية",
        values: [
          {
            title:
              "علاماتنا التجارية تجمع بين أفكار العالم المتفوقة؛ وكذلك المبادئ التي نعيش بها.",
            description:
              "بالنسبة لنموذج نجح بشكل كبير مع العديد من منتجاتنا، نستخدم نفس المعادلة لتشكيل قيمنا المؤسسية.",
          },
          {
            title: "العقل والشغف",
            description:
              "الشغف هو القوة التي تدفعنا؛ والعقل هو ما يوجه جهودنا. نحن نضع قلوبنا وعقولنا في كل تفاصيل العمل لتحقيق أفضل النتائج. نحن شغوفون بعلاماتنا التجارية ومنتجاتنا وعملائنا وأفراد فريقنا، لأنهم السبب في وجودنا، وهذا ما يجعلنا ناجحين.",
          },
          {
            title: "التقاليد والحداثة",
            description:
              "مستوحاة من قيم أسلافنا؛ ومواكبة لابتكارات المستقبل. تحويل القديم إلى جديد هو ميزة مميزة للعديد من منتجات نوتيونز؛ حيث تأخذ شركتنا ما كان جيدًا لسنوات وتجعل منه أفضل بلمسة حديثة. كما نستمر في اتباع القيم التقليدية بينما نبقى في طليعة التكنولوجيا ونجعل الابتكار محور كل ما نقوم به.",
          },
          {
            title: "العائلة والمجتمع",
            description:
              "تدار مؤسستنا بقيم عائلية لصالح المجتمع الذي نخدمه. نوتيونز هي شركة عائلية تفخر بجعل العمل وكأنه منزل. كل فرد في الفريق يلعب دورًا مهمًا ونبني حافزهم من خلال تعزيز الثقة والاستقرار والإبداع والعفوية. كجزء من المجتمع، فإن جهودنا المشتركة تهدف إلى تقديم الأفضل فقط لعملائنا.",
          },
          {
            title: "الرؤية والتركيز",
            description:
              "رؤيتنا واسعة، ولكن تركيزنا دقيق. هدفنا المشترك هو جعل منتجاتنا الخيار المفضل في العالم؛ ونظل ملتزمين بتطوير هويتنا المؤسسية بطريقة تقودنا إلى ذلك. نحن نحدد من نخدم وما نقدمه، لأننا بدلاً من أن نكون كل شيء للجميع، نفضل أن نكون شيئًا مميزًا لشخص ما. هذا ما يحافظ على التزامنا بما هو مهم حقًا ويوجه جهودنا للوصول إلى هناك.",
          },
          {
            title: "التميز والرضا",
            description:
              "يأتي التميز المستمر من الرضا. لضمان التميز، يبدأ ذلك برضا موظفينا. لهذا السبب نعمل على الحفاظ على التواصل المفتوح بين الإدارة والموظفين مع تشجيع التقدم من خلال التدريب والتعليم المستمر. ونفعل نفس الشيء مع عملائنا، من خلال الاستماع إلى اقتراحاتهم لإجراء التحسينات الممكنة والضرورية.",
          },
        ],
      },
    },
    ru: {
      companyProfile: {
        title: "Профиль компании",
        description:
          "Notions Group — это динамичная группа компаний, занимающаяся инновациями, производством, маркетингом и дистрибуцией брендов, которые завоевали любовь потребителей. Штаб-квартира находится в постоянно развивающемся городе Дубай. Мы сосредоточены на создании брендов кондитерских изделий, фиников и закусок, отражающих как наследие, так и современность. Наше путешествие началось в 1992 году, когда наш генеральный директор Фаваз Масри создал первую «безвиновную» Chocodate, ставшую любимым полезным лакомством для потребителей по всему миру.",
      },
      pillars: {
        title: "Notions Group основана на двух столпах",
        items: [
          {
            title: "La Ronda",
            description:
              "Фабрика шоколада в Дубае, специализирующаяся на производстве и дистрибуции FMCG и изысканных брендов Chocodate, шоколада и фиников с использованием самых современных технологий, соответствующих международным гигиеническим стандартам.",
          },
          {
            title: "Star Foods",
            description:
              "Саудовская фабрика солёных закусок, производящая разнообразные чипсы, хрустящие закуски и другие продукты с использованием передовых технологий наиболее эффективным и устойчивым способом.",
          },
        ],
        description:
          "Наши корни находятся на Ближнем Востоке, но наше видение охватывает весь мир. Группа была основана с целью создания уникальных деликатесов и незабываемых моментов через наши культовые бренды для людей всех возрастов и национальностей. Сегодня мы расширяем свои горизонты благодаря постоянным исследованиям, добавляя новые категории и клиентов, не теряя из виду то, что делает нас уникальными, а также наше видение, которое всегда сосредоточено на наших потребителях. Мы гордимся тем, что являемся предпочтительным партнером под частной маркой для многих мировых гигантов, таких как Mars, Carrefour, Lulu, Aldi, Burj Khalifa, Harrods и других. ",
        subDescription:
          "Как семейный бизнес, мы сосредоточены на развитии долгосрочных партнерских отношений с нашими поставщиками, клиентами и сотрудниками. Благодаря нашему постоянному вниманию к качеству, инновациям и деталям, мы можем продолжать нашу бесконечную миссию по созданию ценности для наших потребителей.",
      },
      leadership: {
        title: "Руководящая команда",
        members: [
          {
            name: "Фаваз Аль-Масри",
            position: "Основатель и генеральный директор",
          },
          {
            name: "Разан Аль-Масри",
            position: "Директор по развитию бизнеса, Notions Global",
          },
          {
            name: "Омар Аль-Масри",
            position: "Операционный директор, Notions Global",
          },
          {
            name: "Хазем Аль-Масри",
            position: "Менеджер по операциям, Snacks Division",
          },
        ],
      },
      corporate: {
        title: "Корпоративные ценности",
        values: [
          {
            title:
              "Наши бренды объединяют лучшие представления мира; то же самое касается принципов, которыми мы руководствуемся.",
            description:
              "Для модели, которая так хорошо зарекомендовала себя во многих наших продуктах, мы используем ту же формулу для формирования наших корпоративных ценностей.",
          },
          {
            title: "Разум и страсть",
            description:
              "Страсть — это сила, которая движет нами; разум — это то, что направляет наши усилия. Мы вкладываем свои сердца и умы в каждую деталь бизнеса, чтобы достичь наилучших результатов. Мы гордимся нашими брендами, продуктами, клиентами и сотрудниками, потому что они — причина нашего существования, и это делает нас успешными.",
          },
          {
            title: "Традиции и современность",
            description:
              "Руководствуясь ценностями наших предков; знакомы с инновациями будущего. Превращение старого в новое — это отличительная черта многих продуктов Notions; наша компания берет то, что было хорошим на протяжении многих лет, и делает это лучше с современным подходом. Мы также продолжаем следовать традиционным ценностям, оставаясь на передовом крае технологий и ставя инновации в центр всего, что мы делаем.",
          },
          {
            title: "Семья и сообщество",
            description:
              "Наше предприятие основано на семейных ценностях, на благо сообщества, которому мы служим. Notions — это семейный бизнес, который гордится тем, что превращает бизнес в дом. Каждый член команды играет важную роль, и мы поддерживаем их мотивацию, поощряя доверие, стабильность, творчество и спонтанность. Как часть сообщества, наши совместные усилия направлены на то, чтобы предлагать только лучшее нашим клиентам.",
          },
          {
            title: "Видение и фокус",
            description:
              "Наше видение широкое, но наш фокус узок. Наша общая цель — сделать наши продукты предпочтительным выбором в мире; мы остаемся сосредоточенными на развитии нашей корпоративной идентичности таким образом, чтобы это привело нас к цели. Мы решаем, кого и что обслуживать, потому что, вместо того чтобы быть всем для всех, мы предпочитаем быть чем-то особенным для кого-то. Это помогает нам оставаться верными тому, что действительно важно, и направляет наши усилия на пути к этому.",
          },
          {
            title: "Превосходство и удовлетворение",
            description:
              "Продолжение предоставления превосходства исходит из удовлетворения. Для гарантии превосходства оно начинается с удовлетворенности наших сотрудников. Именно поэтому мы работаем над поддержанием открытого общения между руководством и персоналом, поощряя развитие через обучение и непрерывное образование. Мы также следуем тому же принципу с нашими клиентами, прислушиваясь к их предложениям для внесения возможных и необходимых улучшений.",
          },
        ],
      },
    },
    hi: {
      companyProfile: {
        title: "कंपनी प्रोफ़ाइल",
        description:
          "Notions Group एक गतिशील समूह है जो कंपनियों, नवाचार, उत्पादन, विपणन और वितरण में संलग्न है, जो हमारे उपभोक्ताओं ने पसंद किए हैं। दुबई के हमेशा बदलते शहर में मुख्यालय, हम ऐसे ब्रांडों पर ध्यान केंद्रित करते हैं जो परंपरा और आधुनिकता दोनों को दर्शाते हैं। यात्रा 1992 में शुरू हुई जब हमारे सीईओ, फवाद मसरी, ने दुबई का पहला 'गिल्ट-फ्री' Chocodate बनाया, जो दुनिया भर में जागरूक उपभोक्ताओं के लिए पसंदीदा स्वस्थ और स्वादिष्ट स्नैक बन गया।",
      },
      pillars: {
        title: "Notions Group दो स्तंभों पर आधारित है",
        items: [
          {
            title: "La Ronda",
            description:
              "दुबई स्थित एक चॉकलेट फैक्ट्री जो FMCG और गोरमेट Chocodate, चॉकलेट और डेट ब्रांड्स का उत्पादन और वितरण करती है, जो नवीनतम अत्याधुनिक प्रौद्योगिकी का उपयोग करती है और अंतर्राष्ट्रीय मानकों के तहत स्वच्छता का पालन करती है।",
          },
          {
            title: "Star Foods",
            description:
              "एक सऊदी स्थित सवीरी स्नैक्स फैक्ट्री जो चिप्स, क्रिस्प्स और अन्य स्नैक्स का उत्पादन करती है, जो सबसे प्रभावी, औद्योगिक और सतत विधियों का उपयोग करती है।",
          },
        ],
        description:
          "हमारी जड़ें मध्य पूर्व में हैं, लेकिन हमारी दृष्टि वैश्विक है। समूह का उद्देश्य अद्वितीय स्वादिष्ट वस्तुएं तैयार करना और हमारे प्रतिष्ठित ब्रांडों के माध्यम से सभी आयु वर्ग और राष्ट्रीयताओं के लोगों के लिए अविस्मरणीय क्षण उत्पन्न करना था। आज हम लगातार अनुसंधान के माध्यम से अपनी दुनिया का विस्तार कर रहे हैं, नई श्रेणियाँ और ग्राहक जोड़ रहे हैं, बिना यह खोए कि हमें क्या अनूठा बनाता है, और हमारी दृष्टि जो हमेशा हमारे उपभोक्ताओं के चारों ओर घूमती रही है। हम खुद को वैश्विक शक्तिशाली कंपनियों जैसे Mars, Carrefour, Lulu, Aldi, Burj Khalifa, Harrods, और कई अन्य के लिए पसंदीदा प्राइवेट लेबल पार्टनर के रूप में गर्व महसूस करते हैं।",
        subDescription:
          "एक पारिवारिक व्यवसाय के रूप में, हम अपने आपूर्तिकर्ताओं, ग्राहकों और कर्मचारियों के साथ दीर्घकालिक साझेदारी विकसित करने पर ध्यान केंद्रित करते हैं। गुणवत्ता, नवाचार और विस्तार पर एक निरंतर ध्यान के साथ हम उपभोक्ताओं के लिए मूल्य बनाने के अपने अंतहीन मिशन का पालन करने में सक्षम हैं।",
      },
      leadership: {
        title: "नेतृत्व टीम",
        members: [
          { name: "फवाद अल-मसरी", position: "संस्थापक और सीईओ" },
          {
            name: "रज़ान अल-मसरी",
            position: "मुख्य व्यापार विकास अधिकारी, Notions Global",
          },
          {
            name: "ओमार-अल-मसरी",
            position: "मुख्य संचालन अधिकारी, Notions Global",
          },
          {
            name: "हजेम अल मसरी",
            position: "ऑपरेशंस मैनेजर, स्नैक्स डिवीजन",
          },
        ],
      },
      corporate: {
        title: "कॉर्पोरेट मूल्य",
        values: [
          {
            title:
              "हमारे ब्रांड दुनिया के श्रेष्ठ विचारों को मिलाते हैं; जैसे हम जिन सिद्धांतों का पालन करते हैं",
            description:
              "जो मॉडल हमारे कई उत्पादों के साथ इतने अच्छे से काम करता है, हम वही समीकरण हमारे कॉर्पोरेट मूल्यों को बनाने के लिए इस्तेमाल करते हैं।",
          },
          {
            title: "कारण और जुनून",
            description:
              "जुनून वह शक्ति है जो हमें प्रेरित करता है; कारण वह है जो हमारे प्रयासों को मार्गदर्शन करता है। हम व्यवसाय के हर छोटे से विवरण में अपना दिल और दिमाग लगाते हैं ताकि सर्वोत्तम परिणाम प्राप्त कर सकें। हम अपने ब्रांडों, उत्पादों, ग्राहकों और कर्मचारियों के प्रति जुनूनी हैं, क्योंकि यही हमें यहाँ लाता है, और यही हमें सफल बनाता है।",
          },
          {
            title: "परंपरा और आधुनिकता",
            description:
              "हमारे पूर्वजों के मूल्यों द्वारा प्रेरित; भविष्य की नवाचारों से परिचित। पुराने को लेकर उसे नया बनाना Notions के कई उत्पादों की एक हस्ताक्षर विशेषता है; हमारी कंपनी वर्षों से अच्छा क्या था, उसे लेकर उसे एक आधुनिक मोड़ देती है। हम पारंपरिक मूल्यों का पालन करते रहते हैं, जबकि हम प्रौद्योगिकी की अग्रिम पंक्ति में बने रहते हैं और नवाचार को हमारे सभी कार्यों का केंद्र बनाते हैं।",
          },
          {
            title: "परिवार और समुदाय",
            description:
              "हमारा उद्यम परिवार मूल्यों द्वारा शासित है, हमारे सेवा करने वाले समुदाय के लाभ के लिए। Notions एक पारिवारिक व्यवसाय है जो अपने व्यवसाय को घर बनाने में गर्व महसूस करता है। टीम का प्रत्येक सदस्य एक महत्वपूर्ण भूमिका निभाता है और हम उनके विश्वास, स्थिरता, रचनात्मकता और स्वभाविकता को प्रोत्साहित करके उनकी प्रेरणा निर्माण करते हैं। समुदाय का हिस्सा होने के नाते, हमारे संयुक्त प्रयास केवल हमारे ग्राहकों को सर्वोत्तम प्रदान करने से प्रेरित होते हैं।",
          },
          {
            title: "दृष्टि और ध्यान",
            description:
              "हमारी दृष्टि व्यापक है, लेकिन हमारा ध्यान संकीर्ण है। हमारा समान उद्देश्य हमारे उत्पादों को दुनिया में सबसे पसंदीदा विकल्प बनाना है; हम अपनी कॉर्पोरेट पहचान को इस तरीके से विकसित करने पर ध्यान केंद्रित रखते हैं कि यह हमें वहाँ पहुंचाए। हम तय करते हैं कि हम किसे और क्या सेवा प्रदान करें, क्योंकि हम हर किसी के लिए सब कुछ बनने के बजाय, किसी विशेष के लिए कुछ खास बनना पसंद करते हैं। यही हमें वास्तव में महत्वपूर्ण चीज़ों के प्रति समर्पित रखता है और हमें वहां पहुँचने के लिए हमारे प्रयासों को मार्गदर्शन करता है।",
          },
          {
            title: "उत्कृष्टता और संतोष",
            description:
              "उत्कृष्टता की निरंतर प्रदान करने की शुरुआत संतोष से होती है। उत्कृष्टता की गारंटी देने के लिए, यह हमारे कर्मचारियों की संतुष्टि से शुरू होती है। यही कारण है कि हम प्रबंधन और कर्मचारियों के बीच खुले संवाद बनाए रखते हैं और प्रशिक्षण और निरंतर शिक्षा के माध्यम से प्रगति को प्रोत्साहित करते हैं। हम यही अपने ग्राहकों के साथ भी करते हैं, उनके सुझावों को सुनते हुए जहां संभव और आवश्यक सुधार करते हैं।",
          },
        ],
      },
    },
    zh: {
      companyProfile: {
        title: "公司简介",
        description:
          "Notions Group 是一个充满活力的公司集团，致力于创新、制造、营销和分销我们消费者喜爱的品牌。总部位于不断变化的迪拜，我们专注于打造反映传统与现代的糖果、日期和零食品牌。我们的旅程始于1992年，当时我们的首席执行官Fawaz Masri在迪拜创立了原始的无负担Chocodate，成为全球消费者的首选健康美味零食。",
      },
      pillars: {
        title: "Notions Group 基于两个支柱",
        items: [
          {
            title: "La Ronda",
            description:
              "La Ronda是迪拜一家专注于生产和分销快速消费品和美食Chocodate、巧克力和日期品牌的巧克力工厂，采用最新的先进技术，符合国际卫生标准。",
          },
          {
            title: "Star Foods",
            description:
              "Star Foods 是一家位于沙特的零食工厂，生产多种薯片、炸薯条和其他零食，使用领先的技术，以最高效、最工业化和可持续的方法制造。",
          },
        ],
        description:
          "我们的根基在中东，但我们的愿景是全球性的。该集团成立的目的是创造独特的美味，并通过我们的标志性品牌为各个年龄段和国籍的人们创造难忘的时刻。今天，我们通过持续的研究不断扩展我们的业务，增加新的品类和客户，同时不失去我们独特的优势。我们为成为像Mars、Carrefour、Lulu、Aldi、Burj Khalifa、Harrods等全球大品牌的首选私人标签合作伙伴而感到自豪。",
        subDescription:
          "作为一个家族经营的企业，我们专注于与供应商、客户和员工建立长期的伙伴关系。通过对质量、创新和细节的不断关注，我们能够实现为消费者创造价值的无尽使命。",
      },
      leadership: {
        title: "领导团队",
        members: [
          { name: "Fawaz Al-Masri", position: "创始人兼首席执行官" },
          {
            name: "Razan Al-Masri",
            position: "首席业务发展官，Notions Global",
          },
          {
            name: "Omar-Al-Masri",
            position: "首席运营官，Notions Global",
          },
          {
            name: "Hazem Al Masri",
            position: "运营经理，零食部门",
          },
        ],
      },
      corporate: {
        title: "公司价值",
        values: [
          {
            title: "我们的品牌结合了世界上最优秀的理念；我们的原则也一样",
            description:
              "我们使用相同的方程式来形成我们的企业价值，就像许多产品成功一样。",
          },
          {
            title: "理性与激情",
            description:
              "激情是驱动我们的力量；理性是引导我们努力的方向。我们将心和思维投入到每一个细节中，以达到最佳的结果。我们对我们的品牌、产品、客户和员工充满热情，因为他们是我们存在的原因，这也是我们成功的关键。",
          },
          {
            title: "传统与现代",
            description:
              "由我们的祖先的价值观驱动；熟悉未来的创新。将旧的变成新的，是Notions许多产品的标志性特点；我们的公司将多年来的好东西进行现代化创新，使其更好。我们也继续遵循传统的价值观，并保持在科技的最前沿，将创新置于我们所做一切的中心。",
          },
          {
            title: "家庭与社区",
            description:
              "我们的企业由家庭价值观指导，以造福我们所服务的社区。Notions 是一家家族经营的企业，骄傲地将企业视为家庭。团队的每个成员都发挥着重要作用，我们通过鼓励信任、稳定、创造力和自发性来激发他们的动机。作为社区的一部分，我们的共同努力受到了为客户提供最好服务的激励。",
          },
          {
            title: "愿景与专注",
            description:
              "我们的愿景广阔，但我们的专注却很狭窄。我们的共同目标是使我们的产品成为世界上最受欢迎的选择；我们继续专注于以能够实现这一目标的方式发展我们的企业身份。我们决定服务谁以及服务什么，因为我们宁愿是某人特别喜欢的东西，而不是每个人都喜欢的东西。这使得我们专注于最重要的事情，并引导我们的努力朝着目标前进。",
          },
          {
            title: "卓越与满意",
            description:
              "卓越的持续提供来源于满意。为了保证卓越，从员工的满意开始。这就是为什么我们保持管理层与员工之间的开放沟通，同时通过培训和持续教育促进进步的原因。我们对客户也采取同样的做法，倾听他们的建议，进行必要的改进。",
          },
        ],
      },
    },

    ja: {
      companyProfile: {
        title: "会社概要",
        description:
          "Notions Groupは、革新、製造、マーケティング、および消費者に愛されるブランドの販売を行うダイナミックな企業グループです。変化し続ける都市ドバイを拠点に、私たちは伝統と現代性を反映したお菓子、デーツ、スナックブランドを構築することに焦点を当てています。1992年にCEOのファワズ・マスリがドバイでオリジナルの「罪悪感のない」Chocodateを作ったことから始まり、これは世界中の賢明な消費者にとって健康的で美味しいおやつの選択肢となっています。",
      },
      pillars: {
        title: "Notions Groupは2つの柱で成り立っています",
        items: [
          {
            title: "La Ronda",
            description:
              "La Rondaは、最先端の技術を使用して、FMCG＆グルメChocodate、チョコレート、およびデーツブランドを生産・流通させるドバイ拠点のチョコレート工場です。国際的に認定された衛生基準を満たしています。",
          },
          {
            title: "Star Foods",
            description:
              "Star Foodsは、サウジアラビアに拠点を置くスナック工場で、業界の最先端技術を使用して、効率的、工業的、持続可能な方法で様々なチップスやスナックを製造しています。",
          },
        ],
        description:
          "私たちのルーツは中東にありますが、私たちのビジョンは世界的です。グループは、ユニークな美味しさを作り出し、私たちの象徴的なブランドを通じてすべての年齢層や国籍の人々に忘れられない瞬間を提供することを目指して設立されました。今日、私たちは世界を広げるために継続的な研究を行い、新しいカテゴリや顧客を追加していますが、私たちのユニークさや消費者中心のビジョンを見失うことはありません。私たちは、Mars、Carrefour、Lulu、Aldi、Burj Khalifa、Harrodsなどのグローバル企業にとって、信頼できるプライベートラベルパートナーであることを誇りに思っています。",
        subDescription:
          "家族経営の企業として、私たちはサプライヤー、クライアント、従業員との長期的なパートナーシップを育んでいます。品質、革新、そして細部にわたるこだわりに絶えず焦点を当てることで、消費者に価値を提供し続けるという終わりのない使命を追求しています。",
      },
      leadership: {
        title: "経営陣",
        members: [
          { name: "Fawaz Al-Masri", position: "創設者兼CEO" },
          {
            name: "Razan Al-Masri",
            position: "チーフビジネス開発責任者、Notions Global",
          },
          {
            name: "Omar-Al-Masri",
            position: "最高執行責任者、Notions Global",
          },
          {
            name: "Hazem Al Masri",
            position: "オペレーションマネージャー、スナック部門",
          },
        ],
      },
      corporate: {
        title: "企業の価値",
        values: [
          {
            title:
              "私たちのブランドは、世界の優れた理念を組み合わせています。それが私たちが実践する原則でもあります。",
            description:
              "多くの製品でうまくいったモデルと同じ方程式を使って、私たちの企業の価値を形成しています。",
          },
          {
            title: "理性と情熱",
            description:
              "情熱は私たちを駆り立てる力です。理性は私たちの努力を導くものです。私たちは心と頭を使ってビジネスの細部に注力し、最高の結果を得ることを目指しています。私たちはブランド、製品、顧客、従業員に情熱を持っています。なぜなら、彼らこそが私たちがここにいる理由であり、それが私たちを成功に導いているからです。",
          },
          {
            title: "伝統と現代性",
            description:
              "私たちは先祖の価値観に基づき、未来の革新にも親しんでいます。古いものを新しくすることがNotions製品の特徴です。私たちは何年も続いた良いものをさらに良くするために現代的なひねりを加えています。また、技術の最前線に立ち続け、イノベーションを私たちのすべての活動の中心に据えています。",
          },
          {
            title: "家族とコミュニティ",
            description:
              "私たちの企業は、家族の価値観に基づいて運営されており、私たちが提供するコミュニティの利益のために活動しています。Notionsは家族経営の企業であり、ビジネスを家庭として誇りに思っています。チームの各メンバーは重要な役割を果たしており、信頼、安定性、創造性、自発性を奨励することで彼らのモチベーションを高めています。コミュニティの一員として、私たちの共通の努力は、クライアントに最高のものを提供することに動機付けられています。",
          },
          {
            title: "ビジョンとフォーカス",
            description:
              "私たちのビジョンは広いですが、フォーカスは狭いです。私たちの共通の目標は、世界で最も好まれる製品にすることです。そのため、私たちは企業のアイデンティティを開発し続け、そこに到達する方法に焦点を当てています。私たちは誰に、何を提供するかを決定します。なぜなら、全ての人にすべてを提供するのではなく、特定の誰かに特別な存在であることを選んだからです。これこそが、最も重要なことに専念させ、私たちが目指す方向に努力を向けさせる理由です。",
          },
          {
            title: "卓越性と満足",
            description:
              "卓越性の継続的な提供は満足から始まります。卓越性を保証するためには、従業員の満足から始めなければなりません。だからこそ、私たちは経営陣とスタッフの間でオープンなコミュニケーションを保ち、教育とトレーニングを通じて進歩を促進しています。また、お客様の提案に耳を傾け、必要に応じて改善を行っています。",
          },
        ],
      },
    },
    ko: {
      companyProfile: {
        title: "회사 소개",
        description:
          "Notions Group은 혁신, 제조, 마케팅 및 소비자들이 사랑하는 브랜드의 유통을 담당하는 역동적인 기업 그룹입니다. 끊임없이 변화하는 도시 두바이를 본사로, 우리는 전통과 현대성을 반영한 과자, 대추, 스낵 브랜드를 구축하는 데 집중하고 있습니다. 여정은 1992년에 CEO인 Fawaz Masri가 두바이에서 원래의 죄책감 없는 Chocodate를 창조하면서 시작되었으며, 이는 전 세계에서 건강하고 맛있는 간식으로 선택받는 제품이 되었습니다.",
      },
      pillars: {
        title: "Notions Group은 두 개의 기둥으로 구축되었습니다",
        items: [
          {
            title: "La Ronda",
            description:
              "La Ronda는 최신 최첨단 기술을 사용하여 FMCG 및 고급 Chocodate, 초콜릿 및 대추 브랜드를 생산하고 유통하는 두바이 기반의 초콜릿 공장입니다. 국제적으로 인정받는 위생 기준을 따릅니다.",
          },
          {
            title: "Star Foods",
            description:
              "Star Foods는 효율적이고 산업적이며 지속 가능한 방법으로 다양한 칩, 과자 및 기타 스낵을 제조하는 선도적인 기술을 사용하는 사우디아라비아 기반의 스낵 공장입니다.",
          },
        ],
        description:
          "우리의 뿌리는 중동에 있지만 우리의 비전은 전 세계를 아우릅니다. 이 그룹은 독특한 진미를 만들고 우리의 상징적인 브랜드를 통해 모든 연령대와 국적의 사람들에게 잊을 수 없는 순간을 만들어 내기 위해 설립되었습니다. 오늘날 우리는 지속적인 연구를 통해 새로운 카테고리와 고객을 추가하며, 우리의 독특함과 소비자 중심의 비전을 잃지 않고 있습니다. 우리는 Mars, Carrefour, Lulu, Aldi, Burj Khalifa, Harrods와 같은 글로벌 기업들에게 선호되는 개인 라벨 파트너로서 자부심을 가지고 있습니다.",
        subDescription:
          "가족 경영 기업으로서, 우리는 공급업체, 고객 및 직원과의 장기적인 파트너십을 육성하는 데 집중하고 있습니다. 품질, 혁신 및 세부 사항에 대한 끊임없는 집중을 통해 소비자에게 가치를 창출하는 우리의 끝없는 사명을 추구하고 있습니다.",
      },
      leadership: {
        title: "리더십 팀",
        members: [
          { name: "Fawaz Al-Masri", position: "창립자 겸 CEO" },
          {
            name: "Razan Al-Masri",
            position: "비즈니스 개발 최고 책임자, Notions Global",
          },
          {
            name: "Omar-Al-Masri",
            position: "운영 최고 책임자, Notions Global",
          },
          {
            name: "Hazem Al Masri",
            position: "운영 관리자, 스낵 부문",
          },
        ],
      },
      corporate: {
        title: "기업 가치",
        values: [
          {
            title:
              "우리의 브랜드는 세계의 우수한 Notions을 결합합니다. 우리가 실천하는 원칙도 마찬가지입니다.",
            description:
              "많은 제품에서 성공적인 모델을 사용하여, 우리는 동일한 방정식을 사용하여 우리의 기업 가치를 형성합니다.",
          },
          {
            title: "이성 및 열정",
            description:
              "열정은 우리를 움직이게 하는 힘이고, 이성은 우리의 노력을 이끄는 원칙입니다. 우리는 비즈니스의 모든 작은 세부 사항에 마음과 정신을 담아 최고의 결과를 얻기 위해 노력합니다. 우리는 브랜드, 제품, 고객, 그리고 직원들에 대해 열정적입니다. 그들이 우리가 여기 있는 이유이며, 그것이 우리를 성공으로 이끄는 원동력입니다.",
          },
          {
            title: "전통과 현대성",
            description:
              "우리의 조상들의 가치에 의해 움직이고, 미래의 혁신을 받아들입니다. 오래된 것을 새롭게 만드는 것은 Notions 제품의 특징 중 하나입니다. 우리는 오랫동안 좋은 것이었던 것들을 더 나은 방법으로 만들기 위해 현대적인 변화를 추가합니다. 또한, 우리는 기술의 최전선에서 혁신을 중심에 두고 전통적인 가치를 따르고 있습니다.",
          },
          {
            title: "가족과 커뮤니티",
            description:
              "우리의 기업은 우리가 서비스하는 커뮤니티의 이익을 위해 가족 가치에 의해 운영됩니다. Notions는 비즈니스를 가정으로 만들어 자부심을 느끼는 가족 경영 기업입니다. 팀의 각 구성원은 중요한 역할을 하며, 우리는 신뢰, 안정성, 창의성 및 자발성을 통해 그들의 동기를 부여합니다. 커뮤니티의 일원으로서 우리의 공동 노력이 고객에게 최고의 것을 제공하려는 동기로 이끌립니다.",
          },
          {
            title: "비전과 집중",
            description:
              "우리의 비전은 넓지만, 우리의 집중은 좁습니다. 우리의 공동 목표는 우리의 제품이 세계에서 가장 선호되는 선택이 되는 것입니다. 우리는 그것을 이루기 위한 방법으로 기업 정체성을 개발하는 데 집중합니다. 우리는 누구와 무엇을 서비스할지 결정합니다. 모든 사람에게 모든 것을 제공하는 대신, 우리는 특정 사람들에게 특별한 존재가 되고 싶습니다. 이것이 우리가 중요한 것에 집중하게 하고, 목표를 향해 나아가도록 우리를 이끌어주는 이유입니다.",
          },
          {
            title: "탁월함과 만족",
            description:
              "탁월함의 지속적인 제공은 만족에서 시작됩니다. 탁월함을 보장하려면 직원들의 만족부터 시작해야 합니다. 그래서 우리는 경영진과 직원 간의 열린 소통을 유지하고 교육과 지속적인 학습을 통해 발전을 촉진합니다. 또한 우리는 고객의 제안에 귀 기울여 필요한 개선을 진행합니다.",
          },
        ],
      },
    },
  };

  return (
    <div className="max-width">
      <div className="about">
        <div className="about_banner">
          <img src={require("../../img/profile_pic.webp")} alt="" />
        </div>
        <div className="profile_info">
          <div className="pro_head">
            <h1>{content[language].companyProfile.title} </h1>
            <p>{content[language].companyProfile.description}</p>
          </div>
          <div className="pillars">
            <h2>{content[language].pillars.title}</h2>

            <div className="pils_wrap">
              {content[language].pillars.items.map((item, index) => (
                <div className="pil_item" key={index}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
            <p>{content[language].pillars.description}</p>
            <p>{content[language].pillars.subDescription}</p>
          </div>
          <div className="leader_ship">
            <h1>{content[language].leadership.title}</h1>
            <div className="lead_sec">
              {content[language].leadership.members.map((member, index) => (
                <div className="item" key={index}>
                  <div className="item_pic">
                    <img
                      src={require(`../../img/pic_${index + 1}@2x.jpg`)}
                      alt={member.name}
                    />
                  </div>
                  <h3>{member.name}</h3>
                  <p>{member.position}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="values">
            <h1> {content[language].corporate.title} </h1>

            <div className="all_items">
              {content[language].corporate?.values?.map((item, index) => (
                <div className="item" key={index}>
                  <h2>{item.title}</h2>
                  <p>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Newsletter />
      </div>
    </div>
  );
};

export default About;
