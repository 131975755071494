import React from "react";
import "./Contact.scss";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Form from "../../components/form/Form";
import { useLanguage } from "../../hooks/LanguageContext";

const Contact = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "Let’s contact",
      description:
        "Have a question or need assistance? Send us a message, and we'll get back to you as soon as possible.",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "Notions Group Dubai Investment Park 2 P.O. Box 37782 Dubai, UAE",
    },
    ar: {
      title: "دعونا نتواصل",
      description:
        "هل لديك سؤال أو تحتاج إلى المساعدة؟ أرسل لنا رسالة وسنعود إليك في أقرب وقت ممكن.",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "مجموعة نوتيونز دبي - منتزه الاستثمار 2 صندوق بريد 37782 دبي، الإمارات العربية المتحدة",
    },
    ru: {
      title: "Давайте свяжемся",
      description:
        "Есть вопрос или нужна помощь? Напишите нам, и мы свяжемся с вами как можно скорее.",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "Notions Group Dubai Investment Park 2 P.O. Box 37782 Дубай, ОАЭ",
    },
    hi: {
      title: "हमसे संपर्क करें",
      description:
        "क्या आपके पास कोई सवाल है या आपको सहायता की आवश्यकता है? हमें एक संदेश भेजें, और हम जल्द ही आपसे संपर्क करेंगे।",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address: "नोटियन्स ग्रुप दुबई निवेश पार्क 2 P.O. बॉक्स 37782 दुबई, UAE",
    },
    zh: {
      title: "联系我们",
      description: "有问题或需要帮助吗？给我们留言，我们会尽快回复您。",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "Notions Group Dubai Investment Park 2 P.O. Box 37782 Dubai, UAE",
    },
    ja: {
      title: "お問い合わせ",
      description:
        "質問がありますか？またはサポートが必要ですか？メッセージを送信してください。できるだけ早くお返事いたします。",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "Notions Group Dubai Investment Park 2 P.O. Box 37782 ドバイ、UAE",
    },
    ko: {
      title: "연락처",
      description:
        "질문이 있거나 도움이 필요하신가요? 메시지를 보내주시면 가능한 빨리 연락드리겠습니다.",
      phone: "+971 4 8832236",
      email: "info@notionsgroup.com",
      address:
        "Notions Group Dubai Investment Park 2 P.O. Box 37782 두바이, UAE",
    },
  };


  return (
    <div className="max-width">
      <div className="contact">
        <div className="contact_info_wrap">
          <div className="con_data">
            <h1>{content[language].title}</h1>
            <p>{content[language].description}</p>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <LocalPhoneIcon />
            </div>
            <div className="con_info">
              <p>{content[language].phone}</p>
            </div>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <EmailIcon />
            </div>
            <div className="con_info">
              <p>{content[language].email}</p>
            </div>
          </div>
          <div className="contact_info">
            <div className="con_icon">
              <LocationOnIcon />
            </div>
            <div className="con_info">
              <p>{content[language].address}</p>
            </div>
          </div>
        </div>
        <div className="contact_form">
          <Form />
        </div>
      </div>
    </div>
  );
};

export default Contact;
