import React from "react";
import "./PrivacyPolicy.scss";
import { useLanguage } from "../../hooks/LanguageContext";

const PrivacyPolicy = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "Privacy policy",
      sections: [
        {
          title: "01. Information We Collect",
          items: [
            {
              subtitle: "1.1 Personal Information:",
              text: "When you visit our website or make a purchase, we may collect personal information such as your name, email address, shipping address, billing address, phone number, and payment details. We collect this information to process your orders and provide a personalized experience.",
            },
            {
              subtitle: "1.2 Log Data:",
              text: "Like many websites, we collect log data that includes your IP address, browser type, internet service provider, referring/exit pages, date/time stamps, and clickstream data. We use this information to analyze trends, administer the site, track user movement, and gather demographic information.",
            },
            {
              subtitle: "1.3 Cookies:",
              text: "We use cookies and similar tracking technologies to enhance your browsing experience and customize our website. Cookies are small text files stored on your device that help us remember your preferences and optimize our website's performance.",
            },
          ],
        },
        {
          title: "02. Information Sharing",
          items: [
            {
              subtitle: "2.1 Third-Party Service Providers:",
              text: "We may share your personal information with trusted third-party service providers who help us operate our website, conduct business functions, or provide services on our behalf (e.g., payment processors, shipping companies).",
            },
            {
              subtitle: "2.2 Compliance with the Law:",
              text: "We may disclose your information if required by law, regulation, legal process, or government request.",
            },
            {
              subtitle: "2.3 Business Transfers:",
              text: "In the event of a merger, acquisition, or sale of all or a portion of our business assets, your information may be transferred as part of the transaction.",
            },
          ],
        },
        {
          title: "03. How We Use Your Information",
          items: [
            {
              subtitle: "3.1 Order Processing:",
              text: "We use your personal information to process and fulfill your orders, including shipping, billing, and customer service.",
            },
            {
              subtitle: "3.2 Personalization:",
              text: "We may use your information to personalize your experience, such as recommending products or providing targeted advertisements.",
            },
            {
              subtitle: "3.3 Communication:",
              text: "We may send you emails or other notifications regarding your orders, promotions, or updates about our website. You can opt-out of these communications at any time.",
            },
            {
              subtitle: "3.4 Analytics:",
              text: "We analyze the data we collect to improve our website, enhance user experience, and optimize our marketing strategies.",
            },
            {
              subtitle: "3.5 Legal Compliance:",
              text: "We may use your information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.",
            },
          ],
        },
        {
          title: "04. Data Security",
          items: [
            {
              text: "We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.",
            },
          ],
        },
        {
          title: "05. Your Choices",
          items: [
            {
              text: "You have the right to access, update, or delete your personal information. You can manage your preferences by logging into your account or contacting us directly. You can also choose to disable cookies through your browser settings, although this may affect your browsing experience.",
            },
          ],
        },
        {
          title: "06. Children's Privacy",
          items: [
            {
              text: "Our website is not intended for individuals under the age of 16. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us, and we will promptly remove the information.",
            },
          ],
        },
        {
          title: "07. Changes to the Privacy Policy",
          items: [
            {
              text: "We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this Privacy Policy periodically for any updates.",
            },
          ],
        },
        {
          title: "08. Contact Us",
          items: [
            {
              text: "If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at [insert contact information]. This Privacy Policy applies solely to information collected by our website and does not cover information collected offline or through other channels.",
            },
          ],
        },
      ],
    },
    ja: {
      title: "プライバシーポリシー",
      sections: [
        {
          title: "01. 収集する情報",
          items: [
            {
              subtitle: "1.1 個人情報：",
              text: "当社のウェブサイトにアクセスしたり、購入したりする際に、名前、電子メールアドレス、配送先住所、請求先住所、電話番号、支払い情報などの個人情報を収集することがあります。この情報は、注文を処理し、個別対応の体験を提供するために収集します。",
            },
            {
              subtitle: "1.2 ログデータ：",
              text: "多くのウェブサイトと同様に、IPアドレス、ブラウザの種類、インターネットサービスプロバイダ、参照/終了ページ、日時スタンプ、クリックストリームデータを含むログデータを収集します。この情報は、傾向の分析、サイトの管理、ユーザーの動きの追跡、人口統計情報の収集に使用します。",
            },
            {
              subtitle: "1.3 クッキー：",
              text: "当社は、ブラウジング体験を向上させ、ウェブサイトをカスタマイズするためにクッキーや類似の追跡技術を使用します。クッキーは、ユーザーのデバイスに保存される小さなテキストファイルで、ユーザーの設定を記憶し、ウェブサイトのパフォーマンスを最適化するのに役立ちます。",
            },
          ],
        },
        {
          title: "02. 情報の共有",
          items: [
            {
              subtitle: "2.1 第三者サービス提供者：",
              text: "ウェブサイトの運営、業務機能の実行、または当社の代理でサービスを提供する信頼できる第三者サービス提供者と個人情報を共有することがあります（例：支払い処理業者、配送業者）。",
            },
            {
              subtitle: "2.2 法的遵守：",
              text: "法的な手続き、規制、または政府の要求により、情報を開示することがあります。",
            },
            {
              subtitle: "2.3 事業譲渡：",
              text: "合併、買収、または事業資産の全部または一部の売却が行われる場合、情報が取引の一部として転送されることがあります。",
            },
          ],
        },
        {
          title: "03. 情報の使用方法",
          items: [
            {
              subtitle: "3.1 注文処理：",
              text: "個人情報は、注文を処理し、出荷、請求、カスタマーサービスを行うために使用します。",
            },
            {
              subtitle: "3.2 個別化：",
              text: "情報を使用して、製品の推奨やターゲット広告の提供など、個別の体験を提供することがあります。",
            },
            {
              subtitle: "3.3 通信：",
              text: "注文、プロモーション、またはウェブサイトの更新に関する通知や電子メールを送信することがあります。これらの通信をいつでもオプトアウトできます。",
            },
            {
              subtitle: "3.4 分析：",
              text: "収集したデータを分析し、ウェブサイトの改善、ユーザー体験の向上、マーケティング戦略の最適化を行います。",
            },
            {
              subtitle: "3.5 法的遵守：",
              text: "適用される法令、規制、法的手続き、または政府の要求に従うために情報を使用することがあります。",
            },
          ],
        },
        {
          title: "04. データセキュリティ",
          items: [
            {
              text: "個人情報を不正アクセス、改ざん、開示、または破壊から保護するために適切なセキュリティ対策を実施しています。ただし、インターネット経由の送信方法や電子保存方法は100％安全ではなく、絶対的なセキュリティを保証することはできません。",
            },
          ],
        },
        {
          title: "05. あなたの選択",
          items: [
            {
              text: "個人情報へのアクセス、更新、削除の権利があります。アカウントにログインするか、直接お問い合わせいただくことで、設定を管理できます。また、クッキーを無効にすることもできますが、これによりブラウジング体験に影響を与えることがあります。",
            },
          ],
        },
        {
          title: "06. 子供のプライバシー",
          items: [
            {
              text: "当社のウェブサイトは16歳未満の個人を対象としていません。子供からの個人情報を意図的に収集することはありません。もし、子供からの情報が誤って収集された場合は、お知らせいただければ速やかにその情報を削除いたします。",
            },
          ],
        },
        {
          title: "07. プライバシーポリシーの変更",
          items: [
            {
              text: "当社は、このプライバシーポリシーを随時更新または修正する権利を留保します。変更は、修正されたポリシーをウェブサイトに掲載した時点で即時に効力を持ちます。定期的に本プライバシーポリシーを確認することをお勧めします。",
            },
          ],
        },
        {
          title: "08. お問い合わせ",
          items: [
            {
              text: "このプライバシーポリシーやプライバシーの実践に関して質問、懸念、またはリクエストがある場合は、[連絡先情報を挿入] までご連絡ください。本プライバシーポリシーは、当社のウェブサイトで収集された情報にのみ適用され、オフラインまたは他のチャネルで収集された情報には適用されません。",
            },
          ],
        },
      ],
    },
    ko: {
      title: "개인정보 처리방침",
      sections: [
        {
          title: "01. 우리가 수집하는 정보",
          items: [
            {
              subtitle: "1.1 개인정보:",
              text: "당사의 웹사이트를 방문하거나 구매할 때, 이름, 이메일 주소, 배송 주소, 청구 주소, 전화번호, 결제 세부사항 등 개인정보를 수집할 수 있습니다. 이 정보는 주문을 처리하고 개인화된 경험을 제공하기 위해 수집됩니다.",
            },
            {
              subtitle: "1.2 로그 데이터:",
              text: "많은 웹사이트처럼, 우리는 IP 주소, 브라우저 유형, 인터넷 서비스 제공업체, 참조/종료 페이지, 날짜/시간 스탬프 및 클릭스트림 데이터가 포함된 로그 데이터를 수집합니다. 이 정보는 트렌드를 분석하고, 사이트를 관리하며, 사용자 이동을 추적하고, 인구 통계학적 정보를 수집하는 데 사용됩니다.",
            },
            {
              subtitle: "1.3 쿠키:",
              text: "당사는 브라우징 경험을 향상시키고 웹사이트를 사용자 맞춤화하기 위해 쿠키 및 유사한 추적 기술을 사용합니다. 쿠키는 사용자의 장치에 저장되는 작은 텍스트 파일로, 사용자의 선호를 기억하고 웹사이트 성능을 최적화하는 데 도움이 됩니다.",
            },
          ],
        },
        {
          title: "02. 정보 공유",
          items: [
            {
              subtitle: "2.1 제3자 서비스 제공업체:",
              text: "당사는 웹사이트 운영, 비즈니스 기능 수행 또는 당사를 대신하여 서비스를 제공하는 신뢰할 수 있는 제3자 서비스 제공업체와 개인정보를 공유할 수 있습니다(예: 결제 처리업체, 배송 회사).",
            },
            {
              subtitle: "2.2 법적 준수:",
              text: "법률, 규정, 법적 절차 또는 정부 요청에 의해 정보가 공개될 수 있습니다.",
            },
            {
              subtitle: "2.3 비즈니스 전송:",
              text: "합병, 인수 또는 당사의 모든 또는 일부 사업 자산의 판매가 발생하는 경우, 귀하의 정보는 거래의 일환으로 전송될 수 있습니다.",
            },
          ],
        },
        {
          title: "03. 정보 사용 방법",
          items: [
            {
              subtitle: "3.1 주문 처리:",
              text: "당사는 귀하의 개인정보를 사용하여 주문을 처리하고 이행하며, 배송, 청구 및 고객 서비스를 제공합니다.",
            },
            {
              subtitle: "3.2 개인화:",
              text: "귀하의 경험을 개인화하기 위해 정보를 사용할 수 있으며, 예를 들어 제품 추천 또는 타겟 광고 제공을 할 수 있습니다.",
            },
            {
              subtitle: "3.3 커뮤니케이션:",
              text: "귀하의 주문, 프로모션 또는 웹사이트 업데이트에 관한 이메일 또는 다른 알림을 보낼 수 있습니다. 언제든지 이러한 통신을 거부할 수 있습니다.",
            },
            {
              subtitle: "3.4 분석:",
              text: "수집한 데이터를 분석하여 웹사이트를 개선하고 사용자 경험을 향상시키며 마케팅 전략을 최적화합니다.",
            },
            {
              subtitle: "3.5 법적 준수:",
              text: "적용 가능한 법률, 규정, 법적 절차 또는 집행 가능한 정부 요청에 따라 정보를 사용할 수 있습니다.",
            },
          ],
        },
        {
          title: "04. 데이터 보안",
          items: [
            {
              text: "당사는 귀하의 개인정보를 무단 액세스, 변경, 공개 또는 파괴로부터 보호하기 위해 적절한 보안 조치를 구현합니다. 그러나 인터넷을 통한 전송 방법이나 전자 저장 방식은 100% 안전하지 않으며, 절대적인 보안을 보장할 수 없습니다.",
            },
          ],
        },
        {
          title: "05. 귀하의 선택",
          items: [
            {
              text: "귀하는 개인정보에 접근하고 업데이트하거나 삭제할 권리가 있습니다. 계정에 로그인하거나 직접 연락하여 선호를 관리할 수 있습니다. 또한 브라우저 설정을 통해 쿠키를 비활성화할 수 있지만, 이로 인해 브라우징 경험에 영향을 줄 수 있습니다.",
            },
          ],
        },
        {
          title: "06. 아동의 개인정보 보호",
          items: [
            {
              text: "당사의 웹사이트는 16세 미만의 개인을 대상으로 하지 않습니다. 우리는 아동의 개인정보를 고의로 수집하지 않습니다. 아동의 정보가 잘못 수집된 경우, 이를 신속하게 삭제할 것입니다.",
            },
          ],
        },
        {
          title: "07. 개인정보 처리방침의 변경",
          items: [
            {
              text: "당사는 이 개인정보 처리방침을 언제든지 업데이트하거나 수정할 권리를 보유합니다. 변경 사항은 수정된 정책을 웹사이트에 게시하는 즉시 효력이 발생합니다. 주기적으로 개인정보 처리방침을 확인할 것을 권장합니다.",
            },
          ],
        },
        {
          title: "08. 문의하기",
          items: [
            {
              text: "이 개인정보 처리방침이나 개인정보 처리 관행에 대한 질문, 우려 사항 또는 요청이 있는 경우, [연락처 정보를 삽입]로 연락 주시기 바랍니다. 이 개인정보 처리방침은 당사 웹사이트에서 수집한 정보에만 적용되며, 오프라인 또는 다른 채널을 통해 수집된 정보에는 적용되지 않습니다.",
            },
          ],
        },
      ],
    },
    hi: {
      title: "गोपनीयता नीति",
      sections: [
        {
          title: "01. हम कौनसी जानकारी एकत्र करते हैं",
          items: [
            {
              subtitle: "1.1 व्यक्तिगत जानकारी:",
              text: "जब आप हमारी वेबसाइट पर विजिट करते हैं या खरीदारी करते हैं, तो हम आपकी नाम, ईमेल पता, शिपिंग पता, बिलिंग पता, फोन नंबर और भुगतान विवरण जैसी व्यक्तिगत जानकारी एकत्र कर सकते हैं। हम इस जानकारी को आपके ऑर्डर को प्रोसेस करने और एक व्यक्तिगत अनुभव प्रदान करने के लिए एकत्र करते हैं।",
            },
            {
              subtitle: "1.2 लॉग डेटा:",
              text: "कई वेबसाइटों की तरह, हम लॉग डेटा एकत्र करते हैं जिसमें आपका आईपी पता, ब्राउज़र प्रकार, इंटरनेट सेवा प्रदाता, संदर्भ/निकासी पृष्ठ, तिथि/समय स्टैम्प और क्लिकस्ट्रीम डेटा शामिल होता है। हम इस जानकारी का उपयोग रुझानों का विश्लेषण करने, साइट का प्रशासन करने, उपयोगकर्ता आंदोलन को ट्रैक करने और जनसांख्यिकीय जानकारी एकत्र करने के लिए करते हैं।",
            },
            {
              subtitle: "1.3 कुकीज़:",
              text: "हम आपकी ब्राउज़िंग अनुभव को बढ़ाने और हमारी वेबसाइट को अनुकूलित करने के लिए कुकीज़ और समान ट्रैकिंग तकनीकों का उपयोग करते हैं। कुकीज़ छोटे टेक्स्ट फ़ाइलें होती हैं जो आपके डिवाइस पर स्टोर होती हैं और हमारी वेबसाइट की प्रदर्शन को अनुकूलित करने और आपकी प्राथमिकताओं को याद रखने में मदद करती हैं।",
            },
          ],
        },
        {
          title: "02. जानकारी साझा करना",
          items: [
            {
              subtitle: "2.1 तीसरे पक्ष सेवा प्रदाता:",
              text: "हम आपकी व्यक्तिगत जानकारी को उन विश्वसनीय तीसरे पक्ष सेवा प्रदाताओं के साथ साझा कर सकते हैं जो हमारी वेबसाइट को संचालित करने, व्यावासिक कार्यों को निष्पादित करने, या हमारे behalf पर सेवाएँ प्रदान करने में मदद करते हैं (जैसे, भुगतान प्रोसेसर, शिपिंग कंपनियाँ)।",
            },
            {
              subtitle: "2.2 कानून के अनुरूप:",
              text: "हम आपकी जानकारी का खुलासा कर सकते हैं यदि यह कानून, विनियम, कानूनी प्रक्रिया, या सरकारी अनुरोध द्वारा आवश्यक हो।",
            },
            {
              subtitle: "2.3 व्यापार हस्तांतरण:",
              text: "किसी विलय, अधिग्रहण, या हमारे व्यापार संपत्तियों के सभी या कुछ हिस्से की बिक्री के मामले में, आपकी जानकारी लेन-देन के हिस्से के रूप में स्थानांतरित की जा सकती है।",
            },
          ],
        },
        {
          title: "03. हम आपकी जानकारी का उपयोग कैसे करते हैं",
          items: [
            {
              subtitle: "3.1 आदेश प्रक्रिया:",
              text: "हम आपकी व्यक्तिगत जानकारी का उपयोग आपके आदेशों को प्रोसेस और पूरा करने के लिए करते हैं, जिसमें शिपिंग, बिलिंग, और ग्राहक सेवा शामिल है।",
            },
            {
              subtitle: "3.2 व्यक्तिगत अनुभव:",
              text: "हम आपकी जानकारी का उपयोग आपके अनुभव को व्यक्तिगत बनाने के लिए कर सकते हैं, जैसे उत्पादों की सिफारिश करना या लक्षित विज्ञापन प्रदान करना।",
            },
            {
              subtitle: "3.3 संचार:",
              text: "हम आपको आपके आदेशों, प्रचार, या हमारी वेबसाइट के बारे में अपडेट्स के बारे में ईमेल या अन्य सूचनाएँ भेज सकते हैं। आप कभी भी इन संचारों से बाहर निकल सकते हैं।",
            },
            {
              subtitle: "3.4 विश्लेषिकी:",
              text: "हम जो डेटा एकत्र करते हैं, उसका विश्लेषण करके हम अपनी वेबसाइट को सुधारते हैं, उपयोगकर्ता अनुभव को बढ़ाते हैं, और अपने विपणन रणनीतियों को अनुकूलित करते हैं।",
            },
            {
              subtitle: "3.5 कानूनी अनुपालन:",
              text: "हम आपकी जानकारी का उपयोग लागू कानूनों, विनियमों, कानूनी प्रक्रियाओं, या लागू सरकारी अनुरोधों के अनुपालन में कर सकते हैं।",
            },
          ],
        },
        {
          title: "04. डेटा सुरक्षा",
          items: [
            {
              text: "हम आपकी व्यक्तिगत जानकारी की अनधिकृत पहुँच, परिवर्तन, प्रकटीकरण, या विनाश से सुरक्षा करने के लिए उचित सुरक्षा उपाय लागू करते हैं। हालांकि, कृपया ध्यान दें कि इंटरनेट या इलेक्ट्रॉनिक संग्रहण के माध्यम से प्रसारण की कोई भी विधि 100% सुरक्षित नहीं होती है, और हम पूर्ण सुरक्षा की गारंटी नहीं दे सकते।",
            },
          ],
        },
        {
          title: "05. आपके विकल्प",
          items: [
            {
              text: "आपके पास अपनी व्यक्तिगत जानकारी को एक्सेस, अपडेट, या डिलीट करने का अधिकार है। आप अपने खाते में लॉगिन करके या सीधे हमसे संपर्क करके अपनी प्राथमिकताओं का प्रबंधन कर सकते हैं। आप कुकीज़ को अपने ब्राउज़र सेटिंग्स के माध्यम से निष्क्रिय करने का विकल्प भी चुन सकते हैं, हालांकि इससे आपकी ब्राउज़िंग अनुभव प्रभावित हो सकता है।",
            },
          ],
        },
        {
          title: "06. बच्चों की गोपनीयता",
          items: [
            {
              text: "हमारी वेबसाइट 16 वर्ष से कम उम्र के व्यक्तियों के लिए नहीं है। हम बच्चों से व्यक्तिगत जानकारी जानबूझकर एकत्र नहीं करते हैं। अगर आपको लगता है कि हमने अनजाने में किसी बच्चे से जानकारी एकत्र की है, तो कृपया हमसे संपर्क करें, और हम जानकारी को शीघ्र हटा देंगे।",
            },
          ],
        },
        {
          title: "07. गोपनीयता नीति में परिवर्तन",
          items: [
            {
              text: "हम किसी भी समय इस गोपनीयता नीति को अपडेट या संशोधित करने का अधिकार रखते हैं। कोई भी परिवर्तन तुरंत प्रभावी होगा जब हम संशोधित नीति को अपनी वेबसाइट पर पोस्ट करेंगे। हम आपको प्रोत्साहित करते हैं कि आप समय-समय पर इस गोपनीयता नीति की समीक्षा करें।",
            },
          ],
        },
        {
          title: "08. हमसे संपर्क करें",
          items: [
            {
              text: "अगर आपको इस गोपनीयता नीति या हमारी गोपनीयता प्रथाओं से संबंधित कोई प्रश्न, चिंता या अनुरोध है, तो कृपया [संपर्क जानकारी डालें] पर हमसे संपर्क करें। यह गोपनीयता नीति केवल हमारी वेबसाइट द्वारा एकत्रित जानकारी पर लागू होती है और यह ऑफ़लाइन या अन्य चैनलों से एकत्र की गई जानकारी को कवर नहीं करती है।",
            },
          ],
        },
      ],
    },
    zh: {
      title: "隐私政策",
      sections: [
        {
          title: "01. 我们收集的信息",
          items: [
            {
              subtitle: "1.1 个人信息：",
              text: "当您访问我们的网站或进行购买时，我们可能会收集个人信息，如您的姓名、电子邮件地址、送货地址、账单地址、电话号码和支付详情。我们收集这些信息是为了处理您的订单并提供个性化体验。",
            },
            {
              subtitle: "1.2 日志数据：",
              text: "像许多网站一样，我们会收集包含您的IP地址、浏览器类型、互联网服务提供商、引用/退出页面、日期/时间戳和点击流数据的日志数据。我们使用这些信息来分析趋势、管理网站、跟踪用户活动并收集人口统计信息。",
            },
            {
              subtitle: "1.3 Cookies：",
              text: "我们使用cookies和类似的跟踪技术来增强您的浏览体验并定制我们的网站。Cookies是存储在您的设备上的小文本文件，帮助我们记住您的偏好设置并优化我们网站的性能。",
            },
          ],
        },
        {
          title: "02. 信息共享",
          items: [
            {
              subtitle: "2.1 第三方服务提供商：",
              text: "我们可能会与受信任的第三方服务提供商共享您的个人信息，这些服务提供商帮助我们运营网站、进行业务职能或代表我们提供服务（例如，支付处理商、运输公司）。",
            },
            {
              subtitle: "2.2 法律合规：",
              text: "如果法律、法规、法律程序或政府要求，我们可能会披露您的信息。",
            },
            {
              subtitle: "2.3 商业转让：",
              text: "如果发生并购、收购或我们业务资产的全部或部分销售，您的信息可能作为交易的一部分进行转移。",
            },
          ],
        },
        {
          title: "03. 我们如何使用您的信息",
          items: [
            {
              subtitle: "3.1 订单处理：",
              text: "我们使用您的个人信息来处理和完成您的订单，包括发货、结算和客户服务。",
            },
            {
              subtitle: "3.2 个性化：",
              text: "我们可能会使用您的信息来个性化您的体验，例如推荐产品或提供定向广告。",
            },
            {
              subtitle: "3.3 沟通：",
              text: "我们可能会向您发送电子邮件或其他通知，告知您有关订单、促销活动或我们网站的更新。您可以随时选择退出这些通信。",
            },
            {
              subtitle: "3.4 分析：",
              text: "我们分析收集的数据，以改进我们的网站、增强用户体验，并优化我们的营销策略。",
            },
            {
              subtitle: "3.5 法律合规：",
              text: "我们可能会使用您的信息来遵守适用的法律、法规、法律程序或政府要求。",
            },
          ],
        },
        {
          title: "04. 数据安全",
          items: [
            {
              text: "我们采取适当的安全措施保护您的个人信息，防止未经授权的访问、修改、披露或销毁。然而，请注意，没有任何通过互联网传输或电子存储的方法是100%安全的，我们无法保证绝对的安全性。",
            },
          ],
        },
        {
          title: "05. 您的选择",
          items: [
            {
              text: "您有权访问、更新或删除您的个人信息。您可以通过登录您的账户或直接联系我们来管理您的偏好设置。您还可以选择通过浏览器设置禁用cookies，但这可能会影响您的浏览体验。",
            },
          ],
        },
        {
          title: "06. 儿童隐私",
          items: [
            {
              text: "我们的网站不适用于16岁以下的个人。我们不会故意收集儿童的个人信息。如果您认为我们不小心收集了儿童的信息，请联系我们，我们会立即删除这些信息。",
            },
          ],
        },
        {
          title: "07. 隐私政策的变更",
          items: [
            {
              text: "我们保留随时更新或修改本隐私政策的权利。任何更改将在我们网站上发布修订后的政策后立即生效。我们鼓励您定期查看本隐私政策以获取更新。",
            },
          ],
        },
        {
          title: "08. 联系我们",
          items: [
            {
              text: "如果您对本隐私政策或我们的隐私实践有任何疑问、担忧或要求，请联系我们 [插入联系方式]。本隐私政策仅适用于通过我们网站收集的信息，不涵盖离线或通过其他渠道收集的信息。",
            },
          ],
        },
      ],
    },
    ar: {
      title: "سياسة الخصوصية",
      sections: [
        {
          title: "01. المعلومات التي نجمعها",
          items: [
            {
              subtitle: "1.1 المعلومات الشخصية:",
              text: "عند زيارة موقعنا أو إجراء عملية شراء، قد نقوم بجمع معلومات شخصية مثل اسمك، عنوان بريدك الإلكتروني، عنوان الشحن، عنوان الفواتير، رقم الهاتف، وتفاصيل الدفع. نقوم بجمع هذه المعلومات لمعالجة طلباتك وتوفير تجربة مخصصة.",
            },
            {
              subtitle: "1.2 بيانات السجل:",
              text: "مثل العديد من المواقع، نقوم بجمع بيانات السجل التي تشمل عنوان IP، نوع المتصفح، مزود خدمة الإنترنت، صفحات الإحالة/الخروج، الطوابع الزمنية، وبيانات النقر. نستخدم هذه المعلومات لتحليل الاتجاهات، إدارة الموقع، تتبع حركة المستخدمين، وجمع المعلومات الديموغرافية.",
            },
            {
              subtitle: "1.3 الكوكيز:",
              text: "نستخدم الكوكيز وتقنيات التتبع المماثلة لتعزيز تجربة التصفح الخاصة بك وتخصيص موقعنا الإلكتروني. الكوكيز هي ملفات نصية صغيرة تُخزّن على جهازك تساعدنا في تذكر تفضيلاتك وتحسين أداء الموقع.",
            },
          ],
        },
        {
          title: "02. مشاركة المعلومات",
          items: [
            {
              subtitle: "2.1 مزودو الخدمات من الأطراف الثالثة:",
              text: "قد نشارك معلوماتك الشخصية مع مزودي خدمات من الأطراف الثالثة الذين يساعدوننا في تشغيل موقعنا أو في أداء الأعمال أو تقديم الخدمات نيابة عنا (مثل معالجات الدفع، شركات الشحن).",
            },
            {
              subtitle: "2.2 الامتثال للقانون:",
              text: "قد نكشف عن معلوماتك إذا كان ذلك مطلوبًا بموجب القانون، أو اللوائح، أو الإجراءات القانونية، أو طلب الحكومة.",
            },
            {
              subtitle: "2.3 عمليات نقل الأعمال:",
              text: "في حال حدوث اندماج أو استحواذ أو بيع لجميع أو جزء من أصول أعمالنا، قد يتم نقل معلوماتك كجزء من المعاملة.",
            },
          ],
        },
        {
          title: "03. كيفية استخدام معلوماتك",
          items: [
            {
              subtitle: "3.1 معالجة الطلبات:",
              text: "نستخدم معلوماتك الشخصية لمعالجة وتنفيذ طلباتك بما في ذلك الشحن والفوترة وخدمة العملاء.",
            },
            {
              subtitle: "3.2 التخصيص:",
              text: "قد نستخدم معلوماتك لتخصيص تجربتك مثل التوصية بالمنتجات أو تقديم الإعلانات المستهدفة.",
            },
            {
              subtitle: "3.3 الاتصال:",
              text: "قد نرسل لك رسائل بريد إلكتروني أو إشعارات أخرى بشأن طلباتك أو العروض الترويجية أو التحديثات حول موقعنا. يمكنك إلغاء الاشتراك من هذه الرسائل في أي وقت.",
            },
            {
              subtitle: "3.4 التحليلات:",
              text: "نحلل البيانات التي نجمعها لتحسين موقعنا الإلكتروني، وتعزيز تجربة المستخدم، وتحسين استراتيجيات التسويق.",
            },
            {
              subtitle: "3.5 الامتثال القانوني:",
              text: "قد نستخدم معلوماتك للامتثال للقوانين المعمول بها أو اللوائح أو الإجراءات القانونية أو طلبات الحكومة القابلة للتنفيذ.",
            },
          ],
        },
        {
          title: "04. أمان البيانات",
          items: [
            {
              text: "نقوم بتطبيق التدابير الأمنية المناسبة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو التغيير أو الكشف أو التدمير. ومع ذلك، يجب ملاحظة أنه لا يوجد أي طريقة لنقل البيانات عبر الإنترنت أو تخزينها إلكترونيًا تكون آمنة 100٪، ولا يمكننا ضمان الأمان المطلق.",
            },
          ],
        },
        {
          title: "05. اختياراتك",
          items: [
            {
              text: "لديك الحق في الوصول إلى معلوماتك الشخصية أو تحديثها أو حذفها. يمكنك إدارة تفضيلاتك من خلال تسجيل الدخول إلى حسابك أو الاتصال بنا مباشرة. يمكنك أيضًا اختيار تعطيل الكوكيز عبر إعدادات متصفحك، على الرغم من أن ذلك قد يؤثر على تجربة التصفح الخاصة بك.",
            },
          ],
        },
        {
          title: "06. خصوصية الأطفال",
          items: [
            {
              text: "موقعنا غير موجه للأفراد الذين تقل أعمارهم عن 16 عامًا. نحن لا نجمع معلومات شخصية عن الأطفال عمدًا. إذا كنت تعتقد أننا جمعنا معلومات عن طفل عن غير قصد، يرجى الاتصال بنا وسنقوم بإزالة المعلومات على الفور.",
            },
          ],
        },
        {
          title: "07. التغييرات على سياسة الخصوصية",
          items: [
            {
              text: "نحتفظ بالحق في تحديث أو تعديل سياسة الخصوصية هذه في أي وقت. ستدخل أي تغييرات حيز التنفيذ فور نشر السياسة المعدلة على موقعنا الإلكتروني. نوصيك بمراجعة سياسة الخصوصية هذه بشكل دوري للتأكد من متابعة التحديثات.",
            },
          ],
        },
        {
          title: "08. اتصل بنا",
          items: [
            {
              text: "إذا كان لديك أي أسئلة أو استفسارات أو طلبات بشأن سياسة الخصوصية هذه أو ممارساتنا في مجال الخصوصية، يرجى الاتصال بنا على [insert contact information]. هذه السياسة تنطبق فقط على المعلومات التي تم جمعها من خلال موقعنا الإلكتروني ولا تغطي المعلومات التي تم جمعها من القنوات الأخرى أو عبر الإنترنت.",
            },
          ],
        },
      ],
    },
    ru: {
      title: "Политика конфиденциальности",
      sections: [
        {
          title: "01. Информация, которую мы собираем",
          items: [
            {
              subtitle: "1.1 Личная информация:",
              text: "При посещении нашего сайта или совершении покупки мы можем собирать личную информацию, такую как ваше имя, адрес электронной почты, адрес доставки, адрес для выставления счета, номер телефона и платежные данные. Мы собираем эту информацию для обработки ваших заказов и предоставления персонализированного опыта.",
            },
            {
              subtitle: "1.2 Лог-данные:",
              text: "Как и многие сайты, мы собираем лог-данные, которые включают ваш IP-адрес, тип браузера, интернет-поставщика, страницы входа/выхода, метки времени и данные о кликах. Мы используем эту информацию для анализа тенденций, администрирования сайта, отслеживания передвижения пользователей и сбора демографических данных.",
            },
            {
              subtitle: "1.3 Cookies:",
              text: "Мы используем cookies и аналогичные технологии отслеживания для улучшения вашего опыта при просмотре и персонализации нашего сайта. Cookies — это небольшие текстовые файлы, которые сохраняются на вашем устройстве и помогают нам запоминать ваши предпочтения и оптимизировать производительность сайта.",
            },
          ],
        },
        {
          title: "02. Обмен информацией",
          items: [
            {
              subtitle: "2.1 Сторонние поставщики услуг:",
              text: "Мы можем передавать вашу личную информацию доверенным сторонним поставщикам услуг, которые помогают нам в эксплуатации нашего сайта или предоставлении услуг от нашего имени (например, процессоры платежей, компании по доставке).",
            },
            {
              subtitle: "2.2 Соблюдение законодательства:",
              text: "Мы можем раскрывать вашу информацию, если это требуется по закону, нормативным актам, судебному процессу или запросу правительства.",
            },
            {
              subtitle: "2.3 Перевод бизнеса:",
              text: "В случае слияния, поглощения или продажи всех или части наших бизнес-активов, ваша информация может быть передана как часть сделки.",
            },
          ],
        },
        {
          title: "03. Как мы используем вашу информацию",
          items: [
            {
              subtitle: "3.1 Обработка заказов:",
              text: "Мы используем вашу личную информацию для обработки и выполнения ваших заказов, включая доставку, выставление счетов и обслуживание клиентов.",
            },
            {
              subtitle: "3.2 Персонализация:",
              text: "Мы можем использовать вашу информацию для персонализации вашего опыта, например, для рекомендации продуктов или предоставления целевых рекламных объявлений.",
            },
            {
              subtitle: "3.3 Связь:",
              text: "Мы можем отправлять вам электронные письма или другие уведомления относительно ваших заказов, акций или обновлений на нашем сайте. Вы можете отказаться от этих уведомлений в любой момент.",
            },
            {
              subtitle: "3.4 Аналитика:",
              text: "Мы анализируем собранные данные для улучшения нашего сайта, повышения качества пользовательского опыта и оптимизации наших маркетинговых стратегий.",
            },
            {
              subtitle: "3.5 Соблюдение законодательства:",
              text: "Мы можем использовать вашу информацию для соблюдения применимых законов, нормативных актов, судебных процессов или исполнимых запросов правительства.",
            },
          ],
        },
        {
          title: "04. Безопасность данных",
          items: [
            {
              text: "Мы принимаем соответствующие меры безопасности для защиты вашей личной информации от несанкционированного доступа, изменения, раскрытия или уничтожения. Однако следует отметить, что ни один способ передачи данных через интернет или электронное хранение не является на 100% безопасным, и мы не можем гарантировать абсолютную безопасность.",
            },
          ],
        },
        {
          title: "05. Ваши права",
          items: [
            {
              text: "Вы имеете право получить доступ, обновить или удалить вашу личную информацию. Вы можете управлять своими предпочтениями, войдя в свой аккаунт или связавшись с нами напрямую. Вы также можете отключить cookies через настройки вашего браузера, однако это может повлиять на ваш опыт использования сайта.",
            },
          ],
        },
        {
          title: "06. Конфиденциальность детей",
          items: [
            {
              text: "Наш сайт не предназначен для лиц младше 16 лет. Мы не собираем намеренно личную информацию о детях. Если вы считаете, что мы случайно собрали информацию о ребенке, пожалуйста, свяжитесь с нами, и мы немедленно удалим информацию.",
            },
          ],
        },
        {
          title: "07. Изменения в Политике конфиденциальности",
          items: [
            {
              text: "Мы оставляем за собой право обновлять или изменять эту Политику конфиденциальности в любое время. Изменения вступают в силу сразу после публикации пересмотренной политики на нашем сайте. Мы рекомендуем вам периодически проверять эту Политику конфиденциальности на наличие обновлений.",
            },
          ],
        },
        {
          title: "08. Свяжитесь с нами",
          items: [
            {
              text: "Если у вас есть вопросы, замечания или запросы по поводу этой Политики конфиденциальности или наших практик конфиденциальности, пожалуйста, свяжитесь с нами по [insert contact information].",
            },
          ],
        },
      ],
    },
  };

  return (
    <div className="max-width">
      <div className="privacy">
        <h1>{content[language].title}</h1>
        {content[language].sections.map((section, index) => (
          <section key={index}>
            <h2>{section.title}</h2>
            {section.items.map((item, subIndex) => (
              <div className="item" key={subIndex}>
                {item.subtitle && <span>{item.subtitle}</span>}
                <p>{item.text}</p>
              </div>
            ))}
          </section>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
