import React from "react";
import "./promotions.scss";
import backimg from "../../img/back.jpeg";
import { useLanguage } from "../../hooks/LanguageContext";

const Promotion = () => {
  const { language } = useLanguage();

 const content = {
   en: {
     title: "Enter The Code and Win",
     placeholder: "Click below",
     button: "Add Code",
   },
   ar: {
     title: "أدخل الرمز وامنع",
     placeholder: "انقر أدناه",
     button: "أضف الرمز",
   },
   ru: {
     title: "Введите код и выиграйте",
     placeholder: "Нажмите ниже",
     button: "Добавить код",
   },
   ko: {
     title: "코드를 입력하고 당첨되세요",
     placeholder: "아래를 클릭하세요",
     button: "코드 추가",
   },
   hi: {
     title: "कोड दर्ज करें और जीतें",
     placeholder: "नीचे क्लिक करें",
     button: "कोड जोड़ें",
   },
   zh: {
     title: "输入代码并获胜",
     placeholder: "点击下方",
     button: "添加代码",
   },
   ja: {
     title: "コードを入力して当選",
     placeholder: "下をクリック",
     button: "コードを追加",
   },
 };


  const handleSubmit = (e) => {
    e.preventDefault();
    window.open("https://winatriptodubai.com/Duty-Free/");
  };

  return (
    <div className="max-width">
      <div
        className="promotion"
        style={{
          backgroundImage: `url(${backimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          height: "80vh",
        }}
      >
        <div className="prom_con">
          <div className="pro-content">
            <div className="pro_fom_con">
              <div></div>
              <form onSubmit={handleSubmit}>
                <h1>{content[language].title}</h1>
                <input
                  minLength="9"
                  required
                  disabled
                  type="text"
                  placeholder={content[language].placeholder}
                />
                <button type="submit">{content[language].button}</button>
              </form>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
