import React from "react";
import "./bites.scss";
import bites from "../../img/bite2.png";
import { Link } from "react-router-dom";
import { useLanguage } from "../../hooks/LanguageContext";

const Bites = () => {

      const { language } = useLanguage();
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };


   const content = {
     en: {
       description:
         "Introducing Chocodate Bites, the perfect blend of nutrition and indulgence designed with kids in mind. Each bite-sized piece features a tender medjool date paste filled with mixed nuts, coated in smooth milk chocolate, strawberry or vanilla. These delightful treats offer a balanced combination of natural sweetness, wholesome nuts, and the irresistible taste of chocolate, making them an ideal choice for children's snacking occasions. Whether at home, school, or on the go, Chocodate Bites are a wholesome snack option that kids will love, providing a satisfying treat that parents can feel good about. Encourage healthy habits with a snack that combines fun and nutrition in every bite.",
       orderNow: "Order Now",
     },
     ar: {
       description:
         "نقدم لكم شوكوديت بايتس، المزيج المثالي من التغذية والتمتع المصمم مع وضع الأطفال في الاعتبار. كل قطعة بحجم قضمة تحتوي على معجون تمر مدجول طري مليء بالمكسرات المختلطة، ومغطاة بشوكولاتة الحليب الناعمة أو نكهة الفراولة أو الفانيليا. تقدم هذه الحلوى اللذيذة مزيجًا متوازنًا من الحلاوة الطبيعية والمكسرات المغذية وطعم الشوكولاتة الذي لا يقاوم، مما يجعلها خيارًا مثاليًا للوجبات الخفيفة للأطفال. سواء في المنزل أو في المدرسة أو أثناء التنقل، تعتبر شوكوديت بايتس خيارًا غذائيًا صحيًا يحبه الأطفال، ويمنحهم متعة مرضية يمكن أن يشعر الآباء بالرضا عنها. شجع على العادات الصحية مع وجبة خفيفة تجمع بين المرح والتغذية في كل قضمة.",
       orderNow: "اطلب الآن",
     },
     ru: {
       description:
         "Представляем вам Chocodate Bites — идеальное сочетание питания и наслаждения, созданное с учетом потребностей детей. Каждый маленький кусочек состоит из мягкой пасты из фиников меджул, наполненной смешанными орехами, покрытых гладким молочным шоколадом, клубникой или ванилью. Эти восхитительные лакомства предлагают сбалансированное сочетание естественной сладости, полезных орехов и непреодолимого вкуса шоколада, что делает их идеальным выбором для перекусов детей. Будь то дома, в школе или в пути, Chocodate Bites — это полезная закуска, которую дети полюбят, предоставляя им вкусное угощение, о котором родители могут не переживать. Поощряйте здоровые привычки с перекусом, который сочетает в себе веселье и питание в каждом укусе.",
       orderNow: "Заказать сейчас",
     },
     hi: {
       description:
         "Chocodate Bites पेश है, एक आदर्श मिश्रण पोषण और आनंद का, जो बच्चों के लिए तैयार किया गया है। हर काटने के आकार का टुकड़ा एक नरम मेजूल खजूर पेस्ट से बना होता है, जिसमें मिश्रित मेवे भरे होते हैं, और यह चिकनी दूध चॉकलेट, स्ट्रॉबेरी या वनीला में लिपटा होता है। ये स्वादिष्ट ट्रीट्स प्राकृतिक मिठास, पौष्टिक मेवे और चॉकलेट का अप्रतिरोध्य स्वाद का संतुलित संयोजन पेश करती हैं, जिससे यह बच्चों के नाश्ते के लिए आदर्श विकल्प बनता है। चाहे घर पर हो, स्कूल में हो या चलते-फिरते, Chocodate Bites एक स्वस्थ नाश्ता विकल्प हैं जिसे बच्चे पसंद करेंगे, और यह माता-पिता को संतुष्ट करने वाली एक मीठी चिढ़ाहट प्रदान करेगा। हर एक काटने में मस्ती और पोषण का संयोजन करने वाले एक नाश्ते के साथ स्वस्थ आदतों को बढ़ावा दें।",
       orderNow: "अब ऑर्डर करें",
     },
     zh: {
       description:
         "介绍Chocodate Bites，营养与享受的完美融合，专为孩子设计。每一小口都包含一份柔软的美洲枣泥，内含混合坚果，外层包裹着光滑的牛奶巧克力、草莓或香草。这些美味的零食提供了自然甜味、健康坚果和巧克力无法抗拒的味道的平衡组合，使其成为儿童零食的理想选择。无论是在家、学校还是外出，Chocodate Bites都是孩子们喜爱的健康零食，提供让父母放心的美味小吃。通过每一口结合乐趣和营养的零食，鼓励健康的习惯。",
       orderNow: "立即订购",
     },
     ja: {
       description:
         "Chocodate Bitesをご紹介します。これは、栄養と楽しさが完璧に融合したもので、子供向けにデザインされています。各一口は、柔らかいメジュールデーツペーストとミックスナッツが詰め込まれ、滑らかなミルクチョコレート、ストロベリー、またはバニラでコーティングされています。これらの美味しいお菓子は、自然な甘さ、健康的なナッツ、そしてチョコレートの魅力的な味のバランスの取れた組み合わせを提供し、子供のスナックに最適な選択肢となります。家でも学校でも外出先でも、Chocodate Bitesは子供たちに愛される健康的なスナックで、親が安心できる満足感を提供します。楽しさと栄養を兼ね備えたスナックで、健康的な習慣を促進しましょう。",
       orderNow: "今すぐ注文",
     },
     ko: {
       description:
         "Chocodate Bites을 소개합니다. 영양과 즐거움이 완벽하게 결합된 이 제품은 어린이를 위해 설계되었습니다. 각 한 입 크기는 부드러운 메줄 대추 페이스트로 만들어지며, 혼합된 견과류가 들어 있고, 부드러운 밀크 초콜릿, 딸기 또는 바닐라로 코팅되어 있습니다. 이 맛있는 간식은 자연스러운 단맛, 건강한 견과류, 그리고 초콜릿의 매혹적인 맛이 균형을 이루며 어린이들이 좋아하는 이상적인 간식이 됩니다. 집에서, 학교에서, 또는 이동 중에도 Chocodate Bites는 아이들이 사랑할 건강한 간식 옵션이며 부모가 만족할 수 있는 맛있는 간식을 제공합니다. 즐거움과 영양이 결합된 간식으로 건강한 습관을 장려하세요.",
       orderNow: "지금 주문",
     },
   };


  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bites})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="_wegan">
          <img src={require("../../img/bt_log2.png")} alt="" />
        </div>
        <div className="des">
          <p>{content[language].description} </p>
        </div>
        <div className="bt_brandInfo_wrp">
          <Link to={"/product/101"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #d7df23" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/100"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #df185a" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/102"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/bt_03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #7e579e" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link>
        </div>
        <div className="_symbol">
          <img src={require("../../img/bites_xt.png")} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Bites;
