import React, { createContext, useState, useContext } from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("en");
  const translations = {
    en: { welcome: "Welcome to our app!", change_language: "Change Language" },
    ar: { welcome: "مرحبًا بكم في تطبيقنا!", change_language: "تغيير اللغة" },
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
