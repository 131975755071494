import React from "react";
import "./Newsletter.scss";
import { useLanguage } from "../../hooks/LanguageContext";

const Newsletter = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "Subscribe to get the latest updates",
      description: "Newsletter",
      button: "Subscribe",
      placeholder: "Add email",
    },
    ar: {
      title: "اشترك للحصول على آخر التحديثات",
      description: "النشرة الإخبارية",
      button: "اشتراك",
      placeholder: "أضف البريد الإلكتروني",
    },
    ru: {
      title: "Подпишитесь, чтобы получать последние обновления",
      description: "Новости",
      button: "Подписаться",
      placeholder: "Добавить email",
    },
    hi: {
      title: "ताजे अपडेट प्राप्त करने के लिए सदस्यता लें",
      description: "न्यूज़लेटर",
      button: "सदस्यता लें",
      placeholder: "ईमेल जोड़ें",
    },
    zh: {
      title: "订阅以获取最新更新",
      description: "新闻通讯",
      button: "订阅",
      placeholder: "添加电子邮件",
    },
    ja: {
      title: "最新情報を受け取るために購読",
      description: "ニュースレター",
      button: "購読する",
      placeholder: "メールを追加",
    },
    ko: {
      title: "최신 업데이트를 받으려면 구독하세요",
      description: "뉴스레터",
      button: "구독",
      placeholder: "이메일 추가",
    },
  };


  return (
    <div className="max-width">
      <div className="news_my_container">
        <div className="newsletter __wrap">
          <div className="news_info">
            <p>{content[language].description}</p>
            <h1>{content[language].title}</h1>
          </div>
          <div className="news_form">
            <div className="news_input">
              <input
                type="email"
                name="Add e mail"
                defaultValue={content[language].placeholder}
                id="#"
              />
            </div>
            <div className="News_button">
              <button>{content[language].button}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
