import React from 'react'
import "./vegan.scss";
import bkg from "../../img/bk.png";
import { Link } from 'react-router-dom';
import { useLanguage } from '../../hooks/LanguageContext';


const Vegan = () => {
    const { language } = useLanguage();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const content = {
    en: {
      description:
        "Introducing Chocodate Vegan, a guilt-free indulgence crafted for those who cherish both flavor and ethical choices. Each piece features a plump medjool date filled with a crunchy almond, all coated in rich, dairy-free chocolate. This vegan-friendly treat promises a harmonious blend of natural sweetness and satisfying crunch, making it an ideal choice for discerning palates seeking plant-based delights. Enjoy Chocodate Vegan as a wholesome snack or a thoughtful gift, knowing that every bite is crafted with compassion and quality in mind.",
      orderNow: "Order Now",
    },
    ar: {
      description:
        "نقدم لكم شوكوديت فيجان، متعة خالية من الشعور بالذنب تم تصميمها لأولئك الذين يقدرون النكهات والخيارات الأخلاقية على حد سواء. كل قطعة تحتوي على تمر مدجول ممتلئ باللوز المقرمش، ومغطى بالشوكولاتة الغنية الخالية من الألبان. هذه الحلوى المناسبة للنباتيين تعد مزيجًا متناغمًا من الحلاوة الطبيعية والقرمشة المرضية، مما يجعلها خيارًا مثاليًا لأولئك الذين يبحثون عن لذائذ نباتية. استمتع بشوكوديت فيجان كوجبة خفيفة صحية أو كهدية مدروسة، مع العلم أن كل قضمة تم تحضيرها بعناية وجودة في الاعتبار.",
      orderNow: "اطلب الآن",
    },
    ru: {
      description:
        "Представляем вам Chocodate Vegan, безвиновное наслаждение, созданное для тех, кто ценит как вкус, так и этичные выборы. Каждый кусочек включает в себя сочную финиковую дату, наполненную хрустящим миндалем, покрытую насыщенным шоколадом без молочных продуктов. Этот веганский десерт обещает гармоничное сочетание естественной сладости и удовлетворяющего хруста, что делает его идеальным выбором для искушенных гурманов, ищущих растительные удовольствия. Наслаждайтесь Chocodate Vegan как полезным перекусом или продуманным подарком, зная, что каждый укус создан с заботой и качеством.",
      orderNow: "Заказать сейчас",
    },
    hi: {
      description:
        "Chocodate Vegan पेश है, एक बिना किसी अपराधबोध के आनंद जो उन लोगों के लिए तैयार किया गया है जो स्वाद और नैतिक विकल्प दोनों को महत्व देते हैं। प्रत्येक टुकड़े में एक मोटा मेजूल खजूर होता है, जो कुरकुरी बादाम से भरा होता है और सभी को समृद्ध, डेयरी-मुक्त चॉकलेट में लिपटा होता है। यह शाकाहारी दोस्ताना मिठाई प्राकृतिक मिठास और संतोषजनक कुरकुरेपन का एक सामंजस्यपूर्ण मिश्रण वादा करती है, जिससे यह उन चयनात्मक स्वादों के लिए आदर्श विकल्प बनता है जो पौधों पर आधारित आनंद की तलाश में हैं। Chocodate Vegan का आनंद लें एक स्वास्थ्यवर्धक नाश्ते या एक विचारशील उपहार के रूप में, यह जानते हुए कि हर एक काटने को सहानुभूति और गुणवत्ता के साथ तैयार किया गया है।",
      orderNow: "अब ऑर्डर करें",
    },
    zh: {
      description:
        "介绍Chocodate Vegan，一款无负罪感的享受，专为那些珍视味道和道德选择的人打造。每一块都包含一颗饱满的美洲枣，里面填充了脆杏仁，外层包裹着丰富的无乳制品巧克力。这款适合素食者的美味承诺自然甜味和令人满足的脆感的和谐融合，使其成为追求植物性美味的挑剔味蕾的理想选择。将Chocodate Vegan作为健康的小吃或用心的礼物享受，知道每一口都是以关怀和质量为宗旨精心制作的。",
      orderNow: "立即订购",
    },
    ja: {
      description:
        "Chocodate Veganをご紹介します。これは、味と倫理的選択の両方を大切にする人々のために作られた罪悪感のない贅沢です。各ピースには、サクサクしたアーモンドが詰まったジューシーなメジュールデーツがあり、すべてがリッチで乳製品不使用のチョコレートでコーティングされています。このビーガンフレンドリーなデザートは、自然な甘さと満足感のあるカリカリ感が調和したブレンドを約束し、植物ベースの美味しさを求める目の肥えた味覚に最適な選択肢です。Chocodate Veganは、健康的なスナックとしても、思いやりのあるギフトとしてもお楽しみいただけます。すべての一口が思いやりと品質を考慮して作られていることを知ってください。",
      orderNow: "今すぐ注文",
    },
    ko: {
      description:
        "Chocodate Vegan을 소개합니다. 맛과 윤리적 선택을 모두 소중히 여기는 사람들을 위해 제작된 죄책감 없는 즐거움입니다. 각 조각에는 바삭한 아몬드가 들어간 풍성한 메줄 대추가 들어 있으며, 모두 진한 유제품이 없는 초콜릿으로 코팅되어 있습니다. 이 비건 친화적인 간식은 자연스러운 단맛과 만족스러운 바삭함이 조화를 이루는 것을 약속하며, 식물 기반의 맛있는 간식을 찾는 까다로운 입맛을 가진 이들에게 이상적인 선택입니다. Chocodate Vegan을 건강한 간식이나 사려 깊은 선물로 즐기세요. 모든 한 입은 배려와 품질을 염두에 두고 정성스럽게 만들어졌다는 것을 알고 있습니다.",
      orderNow: "지금 주문",
    },
  };


  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bkg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="wegan">
          <img src={require("../../img/vegan.png")} alt="" />
        </div>
        <div className="des">
          <p>
            {content[language].description}
          </p>
        </div>
        <div className="brandInfo_wrp">
          <Link to={"/product/91"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #743f2b" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/94"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #482565" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/92"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #0872ba" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link>
          {/* <div className="item">
            <div className="item_img">
              <img src={require("../../img/04.png")} alt="" />
            </div>
            <div
              className="item_price"
              style={{ borderTop: "2px solid #0e6e68" }}
            >
              <button>pre order</button>
            </div>
          </div>{" "} */}
          <Link to={"/product/90"} onClick={scrollToTop}>
            <div className="item">
              <div className="item_img">
                <img src={require("../../img/05.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{ borderTop: "2px solid #221f1f" }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
        </div>
        <div className="symbol">
          <img src={require("../../img/symbol2.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Vegan;
