import React from 'react'
import "./gourmet.scss";
import bites from "../../img/bk2.png";
import { Link } from "react-router-dom";
import { useLanguage } from '../../hooks/LanguageContext';


const Gourmet = () => {
    const { language } = useLanguage();
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    const content = {
      en: {
        description:
          "Chocodate Gourmet, where luxury meets indulgence in every bite. Crafted with the finest ingredients, each Chocodate Gourmet piece encapsulates a rich medjool date filled with a whole almond and enrobed in premium chocolate. The result is a harmonious blend of sweet, chewy date, crunchy almond, and velvety chocolate that delights the senses with every taste. Perfect for those who appreciate the finer things in life.",
        promise:
          "Chocodate Gourmet promises a luxurious chocolate experience that is simply irresistible.",
        orderNow: "Order Now",
      },
      ar: {
        description:
          "شوكوديت غورميه، حيث يلتقي الفخامة باللذة في كل قضمة. مصنوع من أجود المكونات، كل قطعة شوكوديت غورميه تحتوي على تمر مدجول غني مليء باللوز كامل وملفوف في شوكولاتة فاخرة. النتيجة هي مزيج متوازن من التمر الحلو والمطاطي، واللوز المقرمش، والشوكولاتة الناعمة التي تسعد الحواس مع كل قضمة. مثالي لأولئك الذين يقدرون الأشياء الفاخرة في الحياة.",
        promise: "شوكوديت غورميه يعد بتجربة شوكولاتة فاخرة لا تقاوم ببساطة.",
        orderNow: "اطلب الآن",
      },
      ru: {
        description:
          "Chocodate Gourmet — это место, где роскошь сочетается с наслаждением в каждом кусочке. Изготовленное из лучших ингредиентов, каждое изделие Chocodate Gourmet представляет собой финик Меджул, наполненный цельным миндалем и покрытый премиум шоколадом. Результат — гармоничное сочетание сладкого, жевательного финика, хрустящего миндаля и бархатистого шоколада, который радует все органы чувств с каждым укусом. Идеально для тех, кто ценит лучшие вещи в жизни.",
        promise:
          "Chocodate Gourmet обещает роскошный шоколадный опыт, которому просто невозможно устоять.",
        orderNow: "Заказать сейчас",
      },
      hi: {
        description:
          "Chocodate Gourmet, जहाँ हर काटने में लक्जरी और लुभावनापन मिलते हैं। बेहतरीन सामग्रियों से तैयार, प्रत्येक Chocodate Gourmet पीस में एक समृद्ध मेजूल खजूर होता है, जिसमें एक पूरा बादाम भरा होता है और प्रीमियम चॉकलेट में लिपटा होता है। परिणामस्वरूप, यह मीठे, चबाने योग्य खजूर, कुरकुरी बादाम, और मखमली चॉकलेट का एक सामंजस्यपूर्ण मिश्रण होता है, जो हर स्वाद के साथ इंद्रियों को प्रसन्न करता है। यह उनके लिए आदर्श है जो जीवन की बेहतरीन चीजों की सराहना करते हैं।",
        promise:
          "Chocodate Gourmet एक शानदार चॉकलेट अनुभव का वादा करता है, जिसे बस नकारा नहीं किया जा सकता।",
        orderNow: "अभी ऑर्डर करें",
      },
      zh: {
        description:
          "Chocodate Gourmet，奢华与纵容在每一口中相遇。采用最优质的原料，每一块Chocodate Gourmet都包含一颗丰富的美洲枣，里面填充了整颗杏仁，外层包裹着优质巧克力。结果是甜美、富有嚼劲的枣子、酥脆的杏仁和柔滑巧克力的和谐结合，每一口都能愉悦感官。完美适合那些欣赏生活中美好事物的人。",
        promise:
          "Chocodate Gourmet承诺为您带来一种奢华的巧克力体验，简直无法抗拒。",
        orderNow: "立即订购",
      },
      ja: {
        description:
          "Chocodate Gourmet、贅沢と甘美が一口ごとに出会う場所。最高の材料で作られたChocodate Gourmetの各ピースは、リッチなメジュールデーツに一粒のアーモンドが詰められ、プレミアムチョコレートで包まれています。その結果、甘くて弾力のあるデーツ、カリッとしたアーモンド、そして滑らかなチョコレートの調和の取れたブレンドが、味わうたびに感覚を喜ばせます。人生の素晴らしいものを大切にする方に最適です。",
        promise:
          "Chocodate Gourmetは、単に抵抗できない贅沢なチョコレート体験を約束します。",
        orderNow: "今すぐ注文",
      },
      ko: {
        description:
          "Chocodate Gourmet, 매 한 입에 호화로움과 유혹이 만나는 곳. 최고의 재료로 제작된 각 Chocodate Gourmet 조각은 풍부한 메줄 대추에 통 아몬드를 채우고 프리미엄 초콜릿으로 감싸져 있습니다. 그 결과, 달콤하고 쫄깃한 대추, 바삭한 아몬드, 부드러운 초콜릿이 조화롭게 어우러져 매번 한 입마다 감각을 기쁘게 합니다. 인생의 고급스러운 것을 아는 이들에게 완벽합니다.",
        promise:
          "Chocodate Gourmet는 단순히 거부할 수 없는 호화로운 초콜릿 경험을 약속합니다.",
        orderNow: "지금 주문",
      },
    };



  return (
    <div className="max-width">
      <div
        className="brandInfo"
        style={{
          backgroundImage: `url(${bites})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="_wegan">
          <img src={require("../../img/g_logo2.png")} alt="" />
        </div>
        <div className="des">
          <p>
            {content[language].description}{" "} <br /><br />
            <strong>{content[language].promise}</strong>{" "}
          </p>
        </div>
        <div className="gom_brandInfo_wrp">
          <Link to={"/product/99"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #6f3f91,0px 5px 10px -3px #6f3f91",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_01.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #6f3f91",
                  backgroundColor: "#6f3f91",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/95"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #0589c5,0px 5px 10px -3px #0589c5",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_02.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #0589c5",
                  backgroundColor: "#0589c5",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/98"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #00a184,0px 5px 10px -3px #00a184",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_03.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #00a184",
                  backgroundColor: "#00a184",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
          <Link to={"/product/104"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #edbc27,0px 5px 10px -3px #edbc27",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_04.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #edbc27",
                  backgroundColor: "#edbc27",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>{" "}
          </Link>

          <Link to={"/product/103"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #e26640,0px 5px 10px -3px #e26640",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_05.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #e26640",
                  backgroundColor: "#e26640",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>

          <Link to={"/product/96"} onClick={scrollToTop}>
            <div
              className="item"
              style={{
                boxShadow:
                  "0px 5px 10px -3px #65b046,0px 5px 10px -3px #65b046",
              }}
            >
              <div className="item_img">
                <img src={require("../../img/g_06.png")} alt="" />
              </div>
              <div
                className="item_price"
                style={{
                  borderTop: "2px solid #65b046",
                  backgroundColor: "#65b046",
                }}
              >
                <button>{content[language].orderNow}</button>
              </div>
            </div>
          </Link>
        </div>
        <div className="__symbol">
          <img src={require("../../img/icons.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Gourmet
