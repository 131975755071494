import React from "react";
import "./Footer.scss";
import Copyright from "../Copyright/Copyright";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import Pay_Icon from "../PaymentIcon/Pay_Icon";
import { useLanguage } from "../../hooks/LanguageContext";

const Footer = () => {
  const { language } = useLanguage();

 const content = {
   en: {
     followUs: "Follow us",
     socialMedia: "@chocodate",
     about: "About",
     termsAndConditions: "T&C",
     privacyPolicy: "Privacy Policy",
     description:
       "Notions Group is a dynamic group of companies innovating, manufacturing, marketing and distributing brands that our consumers have grown to love. Headquartered in the ever-changing city of Dubai, we focus on building confectionary, date and snack brands that reflect both heritage and modernity.",
   },
   ar: {
     followUs: "تابعنا",
     socialMedia: "@chocodate",
     about: "معلومات عنا",
     termsAndConditions: "الشروط والأحكام",
     privacyPolicy: "سياسة الخصوصية",
     description:
       "مجموعة نوتيونز هي مجموعة ديناميكية من الشركات التي تبتكر وتصنع وتسوق وتوزع العلامات التجارية التي أحبها مستهلكونا. يقع مقرها الرئيسي في مدينة دبي المتغيرة باستمرار، ونحن نركز على بناء العلامات التجارية للحلويات والتمور والوجبات الخفيفة التي تعكس كل من التراث والحداثة.",
   },
   ru: {
     followUs: "Следите за нами",
     socialMedia: "@chocodate",
     about: "О нас",
     termsAndConditions: "Условия использования",
     privacyPolicy: "Политика конфиденциальности",
     description:
       "Группа компаний Notions — это динамичная группа компаний, занимающаяся инновациями, производством, маркетингом и распространением брендов, которые полюбили наши потребители. Штаб-квартира расположена в постоянно меняющемся городе Дубай, и мы сосредоточены на создании брендов кондитерских изделий, фиников и снеков, отражающих как наследие, так и современность.",
   },
   hi: {
     followUs: "हमें फॉलो करें",
     socialMedia: "@chocodate",
     about: "हमारे बारे में",
     termsAndConditions: "नियम और शर्तें",
     privacyPolicy: "गोपनीयता नीति",
     description:
       "नोटियन्स समूह एक गतिशील कंपनियों का समूह है जो नवाचार, निर्माण, विपणन और वितरण करता है उन ब्रांडों का जो हमारे उपभोक्ताओं को पसंद हैं। दुबई के कभी बदलते शहर में स्थित मुख्यालय के साथ, हम ऐसे ब्रांडों पर ध्यान केंद्रित करते हैं जो परंपरा और आधुनिकता दोनों को दर्शाते हैं, जैसे कि कन्फेक्शनरी, खजूर और स्नैक ब्रांड।",
   },
   zh: {
     followUs: "关注我们",
     socialMedia: "@chocodate",
     about: "关于我们",
     termsAndConditions: "条款与条件",
     privacyPolicy: "隐私政策",
     description:
       "Notions集团是一个充满活力的公司集团，致力于创新、制造、营销和分销我们的消费者喜爱的品牌。总部位于不断变化的城市迪拜，我们专注于建立反映传统与现代并存的糖果、枣子和零食品牌。",
   },
   ja: {
     followUs: "フォローする",
     socialMedia: "@chocodate",
     about: "私たちについて",
     termsAndConditions: "利用規約",
     privacyPolicy: "プライバシーポリシー",
     description:
       "Notions Groupは、私たちの消費者が愛するブランドを革新、製造、マーケティング、流通するダイナミックな企業グループです。常に変化する都市ドバイに本社を構え、私たちは伝統と現代を反映した菓子、デーツ、スナックブランドの構築に注力しています。",
   },
   ko: {
     followUs: "팔로우하기",
     socialMedia: "@chocodate",
     about: "회사 소개",
     termsAndConditions: "약관",
     privacyPolicy: "개인정보 보호정책",
     description:
       "Notions Group은 소비자가 사랑하는 브랜드를 혁신, 제조, 마케팅 및 유통하는 다이내믹한 기업 그룹입니다. 끊임없이 변화하는 도시 두바이에 본사를 두고 있으며, 우리는 전통과 현대를 반영하는 사탕, 대추, 스낵 브랜드를 만드는 데 집중하고 있습니다.",
   },
 };


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer_wrap">
      <div className="max-width" >
        <div className="footer" >
          <hr style={{backgroundColor:"#c2c2c2",color:"#c2c2c2"}} />
          <div className="footer_my_container">
            <div className="footer_section_one">
              <h1>
                {content[language].followUs} <br />{" "}
                <span1>{content[language].socialMedia}</span1>
              </h1>
              <div className="social_icon">
                <a href="https://www.facebook.com/chocodateglobal/">
                  <FacebookIcon />
                </a>
                <a href="https://www.instagram.com/chocodate/">
                  <InstagramIcon />
                </a>
              </div>
            </div>

            <div className="footer_list">
              <div>
                <ul>
                  <li>
                    <Link to="/about" onClick={scrollToTop}>
                      <p>{content[language].about}</p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/terms-&-conditions" onClick={scrollToTop}>
                      {" "}
                      <p>{content[language].termsAndConditions}</p>{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" onClick={scrollToTop}>
                      {" "}
                      <p>{content[language].privacyPolicy}</p>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="footer_section_two">
              <div className="logo">
                <img src={require("../../img/w-logo.png")} />
              </div>

              <p>{content[language].description}</p>
            </div>
          </div>
        </div>
      </div>
      <Pay_Icon />
    </div>
  );
};

export default Footer;
